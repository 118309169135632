import React, { Component } from 'react';

class ShopItem extends Component {

    render() {
        return (
            <div className='slide text-center'>
                <div className='col-md-12 col-xl-12 mb-3'>
                    <a rel="noopener noreferrer" target='_blank' href={`${this.props.shop_item.product_url}`}>
                        <img className='shop_item_logo_slick' src={this.props.shop_item.product_image.cover.url != null ? this.props.shop_item.product_image.cover.url : "/images/playlist_cover_default.png"} alt={this.props.shop_item.title} />
                    </a>
                    <p className='pt-2 product_title'>{this.props.shop_item.title}</p>
                    <p className='product_price'>{new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(this.props.shop_item.price)}</p>
                </div>
            </div>
        );
    }
}

export default ShopItem