import React, { Component } from 'react'
import { Card, CardImg } from 'reactstrap';
import { Link } from 'react-router-dom';

class MainPost extends Component {
    render () {
        return(
            <div className={this.props.cssClass}>
                <Link className="post_link" to={`/posts/${this.props.post.slug}`}>
                    <Card data-id={this.props.post.id} className="main_card h-100">
                        {this.props.post.wide_photo_local.url == null ?
                            <>
                                {this.props.post.cover && this.props.post.cover.url == null ?
                                    <>
                                        {this.props.post.rsite_thumb_url != null ?
                                            <CardImg top src={`https://rubin-kazan.ru/media` + this.props.post.rsite_thumb_url} alt={this.props.post.title} />
                                            :
                                            <CardImg top src={this.props.post.square_photo.square.url} alt={this.props.post.title} />
                                        }
                                    </>
                                    :
                                    <>
                                        {this.props.post.square_photo.url != null ?
                                            <CardImg top src={this.props.post.square_photo.url != null ? this.props.post.square_photo.square.url : "/images/cover_default.png"} alt={this.props.post.title} />
                                            :
                                            <CardImg top src={this.props.post.cover.old_site.url != null ? this.props.post.cover.old_site.url : "/images/cover_default.png"} alt={this.props.post.title} />
                                        }
                                    </>
                                }
                            </>
                            :
                            <CardImg top src={this.props.post.wide_photo_local.block_page.url} alt={this.props.post.title} />
                        }
                    </Card>
                </Link>
            </div>
        )
    }
}

export default MainPost