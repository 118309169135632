import React, { Component } from 'react'
import api from '../config/api';
import axios from 'axios';
import { getSponsors, parseSponsors } from '../requests/apiCalls';
import SiteBody from "./SiteBody";
import {isMobile} from 'react-device-detect';

class Home extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            last_post_id: null,
            voting: null,
            posts: [],
            new_posts: [],
            slides: [],
            game: null,
            shop_items: [],
            projects: [],
            standings: [],
            sponsors: [],
            voted: false,
            banner: null,
            height: 85
        }
    }
		
    getPosts() {
        return api.get('/api/v1/posts.json', { params: { m: isMobile } })
    }

    getGames() {
        return api.get('/api/v1/games.json?limit=3')
    }

    getNextGame() {
        return api.get('/api/v1/games/rubin/next.json')
    }

    getShopItems() {
        return api.get('/api/v1/shop_items.json')
    }

    getProjects() {
        return api.get('/api/v1/projects.json')
    }

    getStandings() {
        return api.get('/api/v1/standings.json')
    }

    getVoting() {
        return api.get('/api/v1/votings/main.json')
    }

    componentDidMount() {
        axios.all([this.getVoting(), this.getPosts(), this.getNextGame(), this.getShopItems(), this.getProjects(), this.getStandings(), 
            getSponsors()])
          .then(axios.spread((voting_data, posts, game, shop_items, projects, standings, sponsors) => {
              let data = posts.data.posts.data;
              let firstNews = data.slice(0, 8);
              let lastNews = data.slice(8);
              let mainPost = posts.data.main;
              let nextStamp = lastNews[lastNews.length - 1] ? lastNews[lastNews.length - 1].attributes.timestamp : null;
              let game_data = null;
              if(game.data.data && game.data.data.attributes) {
                  game_data = game.data.data.attributes
              }
              this.setState({ fonbet_banner: posts.data.fonbet_banner.data, banner: posts.data.banner.data.attributes, mainPost: mainPost, voted: voting_data.data.voted, voting: voting_data.data.voting.data, nextStamp: nextStamp, posts: firstNews, new_posts: lastNews, slides: posts.data.slides.data, game: game_data, shop_items: shop_items.data.data, projects: projects.data.data, standings: standings.data.data, sponsors: parseSponsors(sponsors.data), loading: false })
          }));
    }

    render() {
        const { fonbet_banner, banner, mainPost, voted, nextStamp, voting, posts, new_posts, slides, game, shop_items, projects, standings, sponsors, loading } = this.state;
        return (
            <>
                <SiteBody fonbet_banner={fonbet_banner} banner={banner} mainPost={mainPost} voted={voted} nextStamp={nextStamp} voting={voting} posts={posts} new_posts={new_posts} slides={slides} game={game} shop_items={shop_items} projects={projects} standings={standings} sponsors={sponsors} loading={loading}/>
            </>
        );
    }
}

export default Home