import React, { Component } from 'react'
import { Table } from 'reactstrap';
import {Link} from "react-router-dom";
import {withRouter} from 'react-router-dom';

class StandingsFull extends Component {

    constructor(props) {
        super(props)
        this.state = {
            team_type: this.props.match.params.team_type,
        }
    }

    render () {
        return(
            <div className='container content pb-5'>
                <div className='header_table pt-2'>
                    <h3>Турнирная таблица</h3>
                </div>
                <br/>
                        <div className='row links_row'>
                            {/* <div className="d-inline p-2 col-xs-12 nav_table_link">
                                <Link className={"standings_team" + (this.state.team_type === 'rubin' ? ' active' : '')} to={`/rubin/standings`}>Главная команда</Link>
                            </div> */}
                            {/* <div className="d-inline p-2 col-xs-12 nav_table_link">
                                <Link className={"standings_team" + (this.state.team_type === 'rubin_youth' ? ' active' : '')} to={`/rubin_youth/standings`}>Молодежная команда</Link>
                            </div> */}
                        </div>
                <br/>
                <Table className='table_standings' borderless>
                    <thead className='standings_head'>
                    <tr className='head_th'>
                        <th></th>
                        <th>Команда</th>
                        <th>М</th>
                        <th>О</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.props.standings.map((standing, index) => {
                        return <tr key={standing.id} className={'main_team_' + standing.is_main}>
                            <td>{index + 1}</td>
                            <td>{standing.name}</td>
                            <td>{standing.games}</td>
                            <td>{standing.points}</td>
                        </tr>
                    })}
                    </tbody>
                </Table>
            </div>
        )
    }
}

export default withRouter(StandingsFull)