import React, { Component } from 'react'
import Tag from "./Tag";
import MetaTags from 'react-meta-tags';
import {ReactTitle} from 'react-meta-tags';

import {
    FacebookShareButton,
    FacebookIcon,
    TwitterShareButton,
    TwitterIcon,
    VKShareButton,
    VKIcon,
    OKShareButton,
    OKIcon
} from 'react-share';

class StaticPageBody extends Component {

    constructor(props) {
        super(props)
        this.state = {
            url: window.location.href
        }
    }

    rawMarkup(){
        let rawMarkup = this.props.page.body
        return { __html: rawMarkup };
    }

    render () {
        return(
            <>
							<div className='container content post_body'>
									<main role="main">
										<ReactTitle title={this.props.page.title} />
										<MetaTags>
												<title>{this.props.page.title}</title>
												<meta name="description" content={this.props.page.body} />
												<meta property="og:title" content={this.props.page.title} />
										</MetaTags>

										<div className="row body_text_class mx-auto">
												<div className='col-12'>
														<div className="pt-5 rounded">
																<h2 className="blog-post-title">{this.props.page.title}</h2>
														</div>
												</div>
												<div className="col-md-12 blog-main">
														<FacebookShareButton
																url={this.state.url}
																quote={this.props.page.title}
																className="some-network">
																<FacebookIcon
																		size={32}
																		round={false} />
														</FacebookShareButton>

														<TwitterShareButton
																url={this.state.url}
																quote={this.props.page.title}
																className="some-network">
																<TwitterIcon
																		size={32}
																		round={false} />
														</TwitterShareButton>

														<VKShareButton
																url={this.state.url}
																quote={this.props.page.title}
																windowWidth={660}
																windowHeight={460}
																className="some-network">
																<VKIcon
																		size={32}
																		round={false} />
														</VKShareButton>

														<OKShareButton
																url={this.state.url}
																quote={this.props.page.title}
																className="some-network">
																<OKIcon
																		size={32}
																		round={false} />
														</OKShareButton>
														<hr/>
														<div className="blog-post">
																<div className="post_body_text" dangerouslySetInnerHTML={this.rawMarkup()}/>
														</div>
														<div className="tag_list">
																{this.props.page.tags.map((tag, index) => {
																		return (<Tag tag={tag.name} key={tag.id} />)
																})}
														</div>
												</div>
										</div>
									</main>
							</div>
            </>
        )
    }
}

export default StaticPageBody