import React, { Component } from 'react'
import { ListGroup } from 'reactstrap';
import LiveItem from "./LiveItem";

class LiveBoard extends Component {

    render() {
        return (
            <>
                <ListGroup className='pb-5' >
                    {this.props.game.live_items.map((live_item, index) => {
                        return <LiveItem live_item={live_item} key={live_item.id} />
                    })}
                </ListGroup>
            </>
        );
    }
}

export default LiveBoard