import React, { Component } from 'react'
import api from '../config/api';
import { getSponsors, parseSponsors } from '../requests/apiCalls';
import axios from "axios";
import VideosBody from "./VideosBody";

class VideosPage extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            videos: [],
            sponsors: []
        }
    }

    getVideos() {
        return api.get('/api/v1/videos.json')
    }

    componentDidMount() {
        axios.all([this.getVideos(), getSponsors()])
            .then(axios.spread((videos, sponsors) => {
                this.setState({ videos: videos.data.data, loading: false, sponsors: parseSponsors(sponsors.data) })
            }));
    }

    render() {
        const { loading, videos, sponsors } = this.state;
        return (
            <>
                <VideosBody sponsors={sponsors} videos={videos} loading={loading} />
            </>
        );
    }
}

export default VideosPage