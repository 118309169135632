import React, { Component } from 'react';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";

class Gallery extends Component {

    render () {

        const options = {
            showIndex: true,
            showBullets: false,
            infinite: true,
            showThumbnails: true,
            showFullscreenButton: true,
            showGalleryFullscreenButton: true,
            showPlayButton: true,
            showGalleryPlayButton: true,
            showNav: true,
            isRTL: false,
            useBrowserFullscreen: false,
            slideDuration: 450,
            slideInterval: 2000,
            thumbnailPosition: this.props.sidebar || 'right',
            showVideo: {},
        }

        return(
                <ImageGallery
                    items={this.props.gallery_slides}
                    lazyLoad={false}
                    originalClass={'img-fluid'}
                    useBrowserFullscreen={options.useBrowserFullscreen}
                    infinite={options.infinite}
                    showBullets={options.showBullets}
                    showFullscreenButton={options.showFullscreenButton && options.showGalleryFullscreenButton}
                    showPlayButton={options.showPlayButton && options.showGalleryPlayButton}
                    showThumbnails={options.showThumbnails}
                    showIndex={options.showIndex}
                    showNav={options.showNav}
                    isRTL={options.isRTL}
                    thumbnailPosition={options.thumbnailPosition}
                    slideDuration={parseInt(options.slideDuration)}
                    slideInterval={parseInt(options.slideInterval)}
                    additionalClass="app-image-gallery"
                />
        )
    }
}

export default Gallery