import React, { Component } from 'react';
import LoadingSpinner from "./LoadingSpinner";
import SiteHead from "./SiteHead";
import PostBody from "./PostBody";
import PlayerNewsContainer from "./PlayerNewsContainer";
import Footer from "./Footer";

class PostContainer extends Component {

    render() {
        return (
            <>
                {this.props.loading ? <LoadingSpinner /> :
                    <>
                        <SiteHead sponsors={this.props.sponsors}/>
                        <PostBody already_voted={this.props.already_voted} voting={this.props.voting} post={this.props.post} gallery={this.props.gallery} gallery_slides={this.props.gallery_slides} similar={this.props.similar} />
                        <PlayerNewsContainer posts={this.props.posts} cssClass='col-md-6 col-xl-3 mb-3' />
                        <Footer sponsors={this.props.sponsors} />
                    </>
                }
            </>
        );
    }
}

export default PostContainer