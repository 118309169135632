import './App.css'
import 'core-js/es/map';
import 'core-js/es/set';
import 'intl';
import 'react-intl';

import React, { Component } from 'react'
import { Route, Switch, withRouter } from 'react-router-dom'
import PostPage from './components/PostPage'
import NotFound from './components/NotFound'
import Home from "./components/Home";
import VideosPage from "./components/VideosPage";
import VideoPage from "./components/VideoPage";
import GalleriesPage from "./components/GalleriesPage";
import GalleryPage from "./components/GalleryPage";
import TeamPage from "./components/TeamPage";
import PlayerPage from "./components/PlayerPage";
import NewsHub from "./components/NewsHub";
import StandingsPage from "./components/StandingsPage";
import CalendarPage from "./components/CalendarPage";
import StaticPageBody from "./components/StaticPage";
import StatisticsPage from "./components/StatisticsPage";
import MatchCenter from "./components/MatchCenter";
import Results from "./components/Results";
import Staging from "./components/Staging";
import Cookies from 'universal-cookie';
import dotenv from 'dotenv';

import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

dotenv.config();

class App extends Component {

    componentWillMount() {
			let mvp = document.getElementById('main_viewport');

			const cookies = new Cookies();
			let isDesktop = cookies.get('desktop');

			if(!isDesktop) {
				mvp.setAttribute('content','width=device-width, initial-scale=1.0, user-scalable=no');
			} else {
				mvp.setAttribute('content','initial-scale=0.1');
			}

			if (!window['YT']) {
				let YT = {loading: 0, loaded: 0};
				window.YT = YT;
			}
			if (!window['YTConfig']) {
				let YTConfig = {'host': 'http://www.youtube.com'};
				window.YTConfig = YTConfig;
			}
    }

    render() {
      return <div>
							<Switch>
									<Route path='/' exact component={withRouter(Home)} />

									<Route path='/posts/:id' exact component={withRouter(PostPage)} />
									<Route path='/ru/news_items/:id' exact component={withRouter(PostPage)} />

									<Route path='/videos' exact component={withRouter(VideosPage)} />
									<Route path='/videos/:id' exact component={withRouter(VideoPage)} />
									<Route path='/galleries' exact component={withRouter(GalleriesPage)} />
									<Route path='/galleries/:id' exact component={withRouter(GalleryPage)} />
									<Route path='/teams/:team_id/players' exact component={withRouter(TeamPage)} />
									<Route path='/teams/:team_id/players/:id' exact component={withRouter(PlayerPage)} />
									<Route path='/news/:team_type?' exact component={withRouter(NewsHub)} />
									<Route path='/news' exact component={withRouter(NewsHub)} />

									<Route path='/:team_type/standings' exact component={withRouter(StandingsPage)} />
									<Route path='/:team_id/calendar' exact component={withRouter(CalendarPage)} />
									<Route path='/:team_type/statistics' exact component={withRouter(StatisticsPage)} />

									<Route path='/games/:id' exact component={withRouter(MatchCenter)} />

									<Route path='/pages/:id' exact component={withRouter(StaticPageBody)} />

									<Route path='/results' exact component={withRouter(Results)} />

									<Route path='/staging' exact component={withRouter(Staging)} />

									<Route component={NotFound} />
							</Switch>
					</div>
  }
}

export default App