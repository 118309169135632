/* eslint-disable */

import React from "react";
import moment from "moment-timezone";
import {Link} from "react-router-dom";

class MainGame extends React.Component {

    render() {
        return (
            <div className='container-fluid text-center bg-img-rubin pt-3 pb-3'>
                    <div className="row">
                                        <div className='col-xl-4 col-md-4 col-3 align-self-center'>
                                            <img className='img-fluid mx-auto d-block' alt={this.props.game.home_team_name} src={this.props.game.home_team_logo} />
                                        </div>
                                        <div className='col-xl-4 col-md-4 col-6 text-center text-game-date'>
                                            <img className='img-fluid mx-auto d-block' alt={this.props.game.tournament_name} src={this.props.game.tournament_logo} />
                                            <p>{this.props.game.stadium}</p>
                                            <p className='game_date'>{moment(this.props.game.game_date).format('DD MMMM')}</p>
                                            <p className='game_time'>{moment(this.props.game.game_date).tz("Europe/Moscow").format('HH:mm')}</p>
                                            
                                            <div className='row justify-content-center'>
                                                    {(this.props.game.home_goals !== null && this.props.game.guest_goals !== null) ?
                                                        <>
                                                            <div className="col-lg-4 col-xs-4 col-sm-4 col-md-4 div_line">
                                                                <Link className='btn buy_button btn_line' to={`/games/${this.props.game.slugged}`}>МАТЧ ЦЕНТР</Link>
                                                            </div>
                                                        </>
                                                        : ''
                                                    }

                                                <div className="col-lg-4 col-xs-4 col-sm-4 col-md-4 div_line">
                                                    <a className='btn buy_button bet_link btn_line' target='_blank' href='https://rubin-kazan.ru/rubin/calendar'>РАСПИСАНИЕ</a>
                                                </div>

                                                    { this.props.game.tickets_url &&
                                                        <>
                                                        <div className="col-lg-4 col-xs-4 col-sm-4 col-md-4 div_line">
                                                            <a href={this.props.game.tickets_url} target='_blank' className='btn buy_button btn_line'>КУПИТЬ БИЛЕТ</a>
                                                        </div>
                                                        </>
                                                    }
                                            </div>
                                        </div>
                                        <div className='col-xl-4 col-md-4 col-3 align-self-center'>
                                            <img className='img-fluid mx-auto d-block' alt={this.props.game.guest_team_name} src={this.props.game.guest_team_logo} />
                                        </div>
                    </div>
            </div>
        );
    }
}

export default MainGame