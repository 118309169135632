import React, { Component } from 'react'
import Footer from "./Footer";
import VideosContainer from "./VideosContainer";
import SiteHead from "./SiteHead";
import LoadingSpinner from "./LoadingSpinner";

class VideosBody extends Component {

    render() {
        return (
            <>
                {this.props.loading ? <LoadingSpinner/> :
                <>
                    <SiteHead sponsors={this.props.sponsors}/>
                    <div className='content mb-5 long_top'>
                        <VideosContainer videos={this.props.videos}/>
                    </div>
                    <hr className="divider"/>
                    <Footer sponsors={this.props.sponsors} />
                </>
                }
            </>
        );
    }
}

export default VideosBody