import React, { Component } from 'react'
import Footer from "./Footer";
import PlayerProfile from "./PlayerProfile";
import PlayerNewsContainer from "./PlayerNewsContainer";
import SiteHead from "./SiteHead";
import LoadingSpinner from "./LoadingSpinner";

class PlayerBody extends Component {

    render() {
        return (
            <>
                {this.props.loading ? <LoadingSpinner/> :
                    <>
                        <SiteHead sponsors={this.props.sponsors}/>
                        <PlayerProfile player_profile={this.props.player_profile} similar={this.props.similar} />
                        {this.props.posts.length > 0 &&
													<hr className="divider"/>
												}
                        <PlayerNewsContainer posts={this.props.posts} cssClass='col-md-6 col-xl-3 mb-3'/>
                        <Footer sponsors={this.props.sponsors} />
                    </>
                }
            </>
        );
    }
}


export default PlayerBody