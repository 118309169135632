import React, { Component } from 'react'
import api from '../config/api';
import { getSponsors, parseSponsors } from '../requests/apiCalls';
import axios from "axios";
import PlayerBody from "./PlayerBody";

class PlayerPage extends Component {

    constructor(props) {
        super(props)
        this.state = {
            team_id: this.props.match.params.team_id,
            id: this.props.match.params.id,
            loading: true,
            player_profile: [],
            posts: [],
            sponsors: []
        }
    }

    getPlayer() {
        return api.get(`/api/v1/teams/${this.state.team_id}/players/${this.state.id}`)
    }

    componentDidMount() {
        axios.all([this.getPlayer(), getSponsors()])
            .then(axios.spread((player_data, sponsors) => {
                this.setState({ sponsors: parseSponsors(sponsors.data), player_profile: player_data.data.player.data.attributes, posts: player_data.data.posts.data, loading: false })
            }));
    }

    render() {
        const { loading, player_profile, posts, sponsors } = this.state;
        return (
            <>
                <PlayerBody sponsors={sponsors} posts={posts} cssClass='col-md-6 col-xl-3 mb-3' player_profile={player_profile} loading={loading}/>
            </>
        );
    }
}

export default PlayerPage