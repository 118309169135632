import React, { Component } from 'react'
import SiteHead from "./SiteHead";
import Footer from "./Footer";
import api from "../config/api";
import axios from "axios";
import {getSponsors, parseSponsors} from "../requests/apiCalls";
import StaticPageBody from "./StaticPageBody";
import LoadingSpinner from "./LoadingSpinner";

class StaticPage extends Component {

    constructor(props) {
        super(props)
        this.state = {
            id: this.props.match.params.id,
            loading: true,
            page: null,
						sponsors: []
        }
    }

    getPage() {
        return api.get(`/api/v1/pages/${this.state.id}`)
    }

    componentDidMount() {
        axios.all([this.getPage(), getSponsors()])
            .then(axios.spread((page, sponsors) => {
                this.setState({ page: page.data.data.attributes, sponsors: parseSponsors(sponsors.data), loading: false })
            })).catch((error) => {
							if (error.response) {
								window.location.href = '/'
							}
					});
		}
		
    render() {
        const { loading, page, sponsors} = this.state;
        return (
          <>
						{loading ? <LoadingSpinner /> :
							<>
                <SiteHead sponsors={sponsors}/>
                <StaticPageBody page={page}/>
								<Footer sponsors={sponsors}/>
							</>}
          </>
        );
    }
}


export default StaticPage