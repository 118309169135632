import React, { Component } from 'react'

class Tag extends Component {

    filterTags(e, tag) {
        window.location.replace('/news?tag=' + tag);
    }

    render () {
        return(
            <>
              <span onClick={(e)=> {this.filterTags(e, this.props.tag.name)}} className="badge-tag badge badge-pill badge-primary bg-rubin">{this.props.tag.name}</span>
            </>
        )
    }
}

export default Tag
