import React, { Component } from 'react'

class Sponsor extends Component {

    render() {
        let sponsor = this.props.sponsor.attributes;
        return (
            <a href={sponsor.link_url} className={`d-inline custom_sponsor`}>
                <img alt={sponsor.title} className={`sponsor_logo img-fluid custom_size_${this.props.size === 2 ? 6 : 4}`} src={(this.props.index === 0 || this.props.index === 1) ? sponsor.logo.url : sponsor.logo.sponsor_main.url}/>
            </a>
        );
    }
}

export default Sponsor