import React, { Component } from 'react'
import Footer from "./Footer";
import PlayersContainer from "./PlayersContainer";
import SiteHead from "./SiteHead";
import LoadingSpinner from "./LoadingSpinner";

class TeamBody extends Component {

    render() {
        return (
            <>
                {this.props.loading ? <LoadingSpinner/> :
                    <>
                        <SiteHead sponsors={this.props.sponsors}/>
                        <PlayersContainer data={this.props.data} teamId={this.props.teamId}/>
                        <Footer sponsors={this.props.sponsors}/>
                    </>
                }
            </>
        );
    }
}

export default TeamBody