import React, { Component } from 'react'
import { Card, CardImg, CardFooter } from 'reactstrap';
import moment from 'moment'
import { Link } from 'react-router-dom';
import CardBody from "reactstrap/es/CardBody";
import CardTitle from "reactstrap/es/CardTitle";

class Video extends Component {
    render () {
        return(
            <div className={`col-md-6 mb-3 ${this.props.cssClass}`}>
                <Link className="post_link" to={`/videos/${this.props.video.slug}`}>
                    <Card className="main_card h-100">
                        <CardImg top src={this.props.video.preview_url != null ? this.props.video.preview_url : "/images/cover_default.png"} alt="Card image cap" />
                        <CardBody>
                            <CardTitle className="post-card-title">{this.props.video.title}</CardTitle>
                        </CardBody>
                        <CardFooter>{moment(this.props.video.created_at).tz('Europe/Moscow').format("DD.MM.YYYY в HH:mm")}</CardFooter>
                    </Card>
                </Link>
            </div>
        )
    }
}

export default Video