import React, { Component } from 'react'
import GalleryItem from '../components/GalleryItem';

class GalleriesContainer extends Component {
    render() {
        return (
            <div className="container">
                <div className={'row row-cards'}>
                            {this.props.galleries.data.map((gallery) => {
                                return <GalleryItem key={gallery.id} gallery={gallery.attributes} />
                            })}
                </div>
                <div className="container h-100">
                    <div className="row h-100 justify-content-center align-items-center">
                        {this.props.pagination}
                    </div>
                </div>
            </div>
        );
    }
}

export default GalleriesContainer