import api from '../config/api';

export const getSeasons = () => {
    return api.get('/api/v1/seasons.json')
}

export const getSponsors = () => {
    return api.get('/api/v1/sponsors.json')
}

export const parseSponsors = (sponsors) => {
    const groupedMap = sponsors.data.reduce(
        (entryMap, e) => entryMap.set(e.attributes.group, [...entryMap.get(e.attributes.group)||[], e]),
        new Map()
    );

    let arr = []
    for (const entry of groupedMap.entries()) {
        arr.unshift(entry)
    }

    arr.sort(function(a, b) {
        return a[0] - b[0];
    });
    return arr
}