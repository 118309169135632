import React, { Component } from 'react'
import Video from '../components/Video';
import LoadingSpinner from "./LoadingSpinner";

class VideosContainer extends Component {
    render() {
        return (
            <div className="container">
                <h2 className="videos_title">Последние видео</h2>

                {this.props.loading ? <LoadingSpinner /> :
                    <div className={'row row-cards'}>
                        {this.props.videos.map((video) => {
                            return <Video cssClass='col-xl-4' video={video.attributes} key={video.id} />
                        })}
                    </div>}
            </div>
        );
    }
}

export default VideosContainer