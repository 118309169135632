import React from 'react';

class LoadingSpinner extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            player: null
        }
    }

    render() {
        return (
            <div className="center-div">
                <div className='load_spinner'>
                    <div><i className="fa fa-spinner fa-spin" /> Загрузка...</div>
                </div>
            </div>
        )
    }
}

export default LoadingSpinner;
