import React, { Component } from 'react'
import { Table } from 'reactstrap';
import PlayerStatisticsRow from "./PlayerStatisticsRow";
import {Link, withRouter} from "react-router-dom";

class StatisticsTable extends Component {

    constructor(props) {
        super(props)
        this.state = {
            team_type: this.props.match.params.team_type
        }
    }

    getPlayer(player) {
        return player.attributes
    }

    render () {
        return(
            <div className='container content pb-5'>
                <div className='header_table'>
                    <h3>Центр данных</h3>
                    <h5>Статистика</h5>
                </div>
                <br/>
                <div className='row links_row'>
                    <div className="d-inline p-2 col-xs-12 nav_table_link">
                        <Link className={"standings_team" + (this.state.team_type === 'rubin' ? ' active' : '')} to={`/rubin/statistics`}>Главная команда</Link>
                    </div>
                    {/* <div className="d-inline p-2 col-xs-12 nav_table_link">
                        <Link className={"standings_team" + (this.state.team_type === 'rubin_youth' ? ' active' : '')} to={`/rubin_youth/statistics`}>Молодежная команда</Link>
                    </div> */}
                </div>
                <br/>
                <Table className='table_standings' borderless>
                    <thead className='standings_head'>
                    <tr className='head_th'>
                        <th>#</th>
                        <th>ФИО</th>
                        <th>ИГРЫ</th>
                        <th>МИН</th>
                        <th>ГОЛ</th>
                        <th>ПАС</th>
                        <th>ЖК</th>
                        <th>КК</th>
                        <th>ПРОП.</th>
                        <th>СУХ.</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.props.players.map((player, index) => {
                        return <PlayerStatisticsRow key={player.id} player={player.attributes}/>
                    })}
                    </tbody>
                </Table>
            </div>
        )
    }
}

export default withRouter(StatisticsTable)