import React, { Component } from 'react'
import LoadingSpinner from "./LoadingSpinner";
import moment from "moment";
import Tag from "./Tag";
import YouTube from "@u-wave/react-youtube";

class VideoBody extends Component {

    render () {
        return(
            <>
                <div className='container content video_body'>
                    <main role="main">
                        {this.props.loading ? <LoadingSpinner /> :
                            <>
                                <div className='container-fluid'>
                                    <div className="embed-responsive embed-responsive-16by9 item text-center slide_bg_black videoWrapper">
                                        <YouTube className='embed-responsive-item embed-player' key={this.props.video.slug} width="580" height="220" video={this.props.video.video_id} muted={false}  />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="p-3 p-md-5 rounded">
                                        <h2 className="blog-post-title"> {this.props.video.title}</h2>
                                        <p className="blog-video-meta">{moment(this.props.video.created_at).format('DD MMM YYYY')}</p>
                                        <p>{this.props.video.description}</p>
                                        <div className="tag_list">
                                            {this.props.video.tags && this.props.video.tags.map((tag, index) => {
                                                return (<Tag tag={tag.name} key={index} />)
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </>}
                    </main>
                </div>
            </>
        )
    }
}

export default VideoBody