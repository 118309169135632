import React from 'react';
import {BrowserView, MobileView} from "react-device-detect";

class Banner extends React.Component {
    render() {
        return (
					<div className='popup'>
							<div className="container h-100">
								<div className="row align-items-center h-100">
										<div className="col-8 mx-auto">
												<div className="banner_header text-center">
													<BrowserView>
														<a href={this.props.banner.banner_url}>
															<img alt={this.props.banner.name} src={this.props.banner.banner_image.url} className="img-fluid" />
														</a>
													</BrowserView>
													<MobileView>
														<a href={this.props.banner.banner_url}>
															<img alt={this.props.banner.name} src={this.props.banner.banner_mobile.url} className="img-fluid" />
														</a>
													</MobileView>
													<button type="button" className="close d-block text-center mx-auto" data-dismiss="modal" aria-label="Close">
														<span onClick={this.props.closePopup} aria-hidden="true">×</span>
													</button>
												</div>
										</div>
								</div>
						</div>
					</div>
        )
    }
}

export default Banner;


