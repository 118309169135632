import React, { Component } from 'react'
import api from '../config/api';
import axios from "axios";
import {getSponsors, parseSponsors} from "../requests/apiCalls";
import PostContainer from "./PostContainer";

class PostPage extends Component {

    constructor(props) {
        super(props)
        this.state = {
            id: this.props.match.params.id,
            loading: true,
            post: null,
            posts: [],
            similar: [],
            gallery: null,
            gallery_slides: [],
            sponsors: [],
            already_voted: false
        }
    }

    getPosts() {
        let id = this.state.id;
        const pathName = this.props.location.pathname;
        if(pathName.indexOf('/ru/news_items') !== -1) {
            let pathArr = pathName.split("-");
            pathArr.shift();
            id = pathArr.join('-');
        }
        return api.get(`/api/v1/posts/${id}`)
    }

    componentDidMount() {
        axios.all([this.getPosts(), getSponsors()])
            .then(axios.spread((post_data, sponsors) => {

                let gallery_data = post_data.data.gallery.data
                let gallery_slides = []

                if(gallery_data) {
                    let data = gallery_data.attributes
                    data.gallery_images.map((gallery_image, index) => (
                        gallery_slides.push( { original: gallery_image.image.gallery_image.url, thumbnail: gallery_image.image.gallery_image.url })
                    ))
                }
                if(post_data.data.voting.data) {
                    let voting = post_data.data.voting.data.attributes
                    this.setState({voting: voting})
                }
                let voted = post_data.data.already_voted
                this.setState({ already_voted: voted, sponsors: parseSponsors(sponsors.data), loading: false, post: post_data.data.post.data.attributes, posts: post_data.data.posts.data, similar: post_data.data.similar.data, gallery: gallery_data, gallery_slides: gallery_slides })
            })).catch((error) => {
							if (error.response) {
								window.location.href = '/'
							}
					});
    }

    render() {
        const { loading, already_voted, voting, post, posts, similar, gallery, gallery_slides, sponsors } = this.state;
        return (
            <>
                <PostContainer loading={loading} already_voted={already_voted} voting={voting} post={post} posts={posts} similar={similar} gallery={gallery} gallery_slides={gallery_slides} sponsors={sponsors} />
            </>
        );
    }
}

export default PostPage