import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'babel-polyfill'
import './config/polyfills'
import './globals.js';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { BrowserRouter } from 'react-router-dom';

ReactDOM.render(
    <BrowserRouter >
        <App />
    </BrowserRouter>, document.getElementById('root'));

serviceWorker.unregister();
