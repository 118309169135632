import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SquadPlayerItem from "./SquadPlayerItem";

class SquadSlider extends React.Component {

    render() {
        let settings = {
            dots: true,
            infinite: false,
            arrows: true,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 4,
            swipeToSlide: true,
            initialSlide: 0,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        adaptiveHeight: true,
                        infinite: true,
                        dots: false
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        adaptiveHeight: true,
                        slidesToScroll: 1,
                        initialSlide: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        adaptiveHeight: true,
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
				};
        return (
            <div className="container-fluid" key={'custom_' + this.props.sliderId}>
                <h3 className='position_title'>{this.props.players[0].attributes.squad.name}</h3>
                <Slider {...settings} className={'players_slider slider' + this.props.sliderId} ref={'slider' + this.props.sliderId} >
                    {this.props.players.map((player) => {
                        return (<SquadPlayerItem teamId={this.props.teamId} player={player.attributes} key={player.id} />)
                    })}
                </Slider>
                <hr className="team_divider" />
            </div>
        );
    }
}

export default SquadSlider