import React, { Component } from 'react'
import LoadingSpinner from "./LoadingSpinner";
import SquadSlider from "./SquadSlider";
class PlayersContainer extends Component {

    render() {
        return (
            <div className="container content">
                {this.props.loading ? <LoadingSpinner /> :
                    <div className={'row row-cards'}>
                        {this.props.data.map((e, index) => {
                            return <SquadSlider teamId={this.props.teamId} key={e[0]} sliderId={e[0]} players={e[1]} />
                        })}
                    </div>}
            </div>
        );
    }
}

export default PlayersContainer