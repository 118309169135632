import React, { Component } from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom';
import {BrowserView, MobileView} from "react-device-detect";

class GalleryItem extends Component {

    buildCustomOldUrl(gallery) {
				let imgDefault = "/images/cover_default.png";
				if(gallery.old_images.length > 0) {
					let firstImage = gallery.old_images[0][0];
					if(firstImage && gallery.old_title_image === '/images/original/missing.png') {
							imgDefault = 'https://rubin-kazan.ru/media' + firstImage.replace(/original/gi, 'title_image');
					} else {
							imgDefault = 'https://rubin-kazan.ru/media' + this.props.gallery.old_title_image
					}
					return imgDefault;
				} else {
					return null;
				}
    }

    render () {
        let imgUrl = (this.props.gallery.title_image && this.props.gallery.title_image.preview.url != null) ? this.props.gallery.title_image.preview.url : "/images/cover_default.png";
        let old_image = this.buildCustomOldUrl(this.props.gallery);
        return(
            <div className='col-md-6 col-xl-4 mb-3'>
                <Link className={"post_link gallery"} to={`/galleries/${this.props.gallery.slug}`}>
                    <div className='custom_gallery_title'>
                        {old_image ?
                            <>
                            <BrowserView>
                                <img alt={this.props.gallery.title} src={old_image} className='custom_image_gallery w-100 position-relative mx-auto' />
                            </BrowserView>
                            <MobileView>
                                <img alt={this.props.gallery.title} src={old_image} className='img-fluid position-relative mx-auto' />
                            </MobileView>
                            </>
                        :
                            <img alt={this.props.gallery.title} src={imgUrl} className='img-fluid position-relative mx-auto' />
                        }
                        <div className="carousel-caption custom_caption">
                            <h5 className="">{this.props.gallery.title}</h5>
                            <b>{moment(this.props.gallery.created_at).tz('Europe/Moscow').format("DD.MM.YYYY в HH:mm")}</b>
                        </div>
                    </div>
                </Link>
            </div>
        )
    }
}

export default GalleryItem