import React, { Component } from 'react'

class MatchCenterHeader extends Component {

    render() {
        return (
                    <div className="content match_bg">
                        <div className='text-center pb-3'>
                            <div className="row">
                                <div className='col-xl-4 col-md-4 col-4 align-self-center'>
                                    <img className='img-fluid mx-auto d-block' alt={this.props.game.home_team_name} src={this.props.game.home_team_logo} />
                                </div>
                                <div className='col-xl-4 col-md-4 col-4 text-center text-game-date'>
                                    <img className='img-fluid mx-auto d-block' alt={this.props.game.tournament_name} src={this.props.game.tournament_logo} />
                                    <p>{this.props.game.stadium}</p>
                                    <div className="s-match-instance-info">
                                        <div className="s-match-instance-info-items">
                                            <div className="s-match-instance-info-items-score">
                                                <strong className="s-match-instance-info-items-score__item s-match-instance-info-items-score__item--home">{this.props.game.home_goals || 0}
                                                </strong>
                                                <span className="s-match-instance-info-items-score__separator">-</span>
                                                <strong className="s-match-instance-info-items-score__item s-match-instance-info-items-score__item--away">{this.props.game.guest_goals || 0}
                                                </strong>
                                            </div>
                                            <div className="row">
                                                <div className='col-sm-6'>
                                                    <br/>
                                                    {this.props.goals[1].map((live_item) => {
                                                        return <p key={live_item.id}>{live_item.affected_people} '{live_item.minute}</p>
                                                    })}
                                                </div>
                                                <div className='col-sm-6'>
                                                    <br/>
                                                    {this.props.goals[0].map((live_item) => {
                                                        return <p key={live_item.id}>{live_item.affected_people} '{live_item.minute}</p>
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-xl-4 col-md-4 col-4 align-self-center'>
                                    <img className='img-fluid mx-auto d-block' alt={this.props.game.guest_team_name} src={this.props.game.guest_team_logo} />
                                </div>
                            </div>
                        </div>
                    </div>
        );
    }
}

export default MatchCenterHeader

