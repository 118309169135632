import React, { Component } from 'react';
import Standings from "./Standings";
import VotingWidget from "./VotingWidget";
import MainPost from "./MainPost";

class NowContainer extends Component {
    render () {
        return(
            <div className='container mt-5'>
                <section>
                    <div>
                        <div className={`row mx-auto col-lg-${(this.props.voting ? '12' : '9')}`}>
                          <div className='col-md-8 mb-4 mx-auto block_poll'>
                              {this.props.voting ?
                                  <VotingWidget voted={this.props.voted} voting={this.props.voting.attributes}/>
                                  :
                                  <MainPost cssClass='row mr-10' post={this.props.post}/>
                              }
                          </div>
                          <div className='col-md-4 mb-4 mx-auto custom_table'>
                              <Standings voting={this.props.voting} standings={this.props.standings} />
                          </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

export default NowContainer