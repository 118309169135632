import React, { Component } from 'react'
import { ListGroup } from 'reactstrap';
import LoadingSpinner from "./LoadingSpinner";
import VotingItem from "./VotingItem";
import Alert from "reactstrap/es/Alert";

class Voting extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isVoted: false
        }
        this.getVoted = this.getVoted.bind(this);
    }

    getVoted() {
        this.setState({ isVoted: true })
    }

    render () {
        return(
            <div className='row justify-content-center'>
                {this.props.loading ? <LoadingSpinner/> :
                    <>
                    {!this.state.isVoted &&
                            <ListGroup className='w-100 inside_class_vote'>
                                {this.props.voting.voting_items.data.map((voting_item) => {
                                    return <VotingItem setVoted={this.getVoted}
                                                       voting_id={this.props.voting.id}
                                                       id={voting_item.id}
                                                       voting_item={voting_item.attributes}
                                                       key={voting_item.id}/>
                                })}
                            </ListGroup>
                    }
                    </>
                }
                {this.state.isVoted &&
                    <>
                        <Alert color="danger">
                            Спасибо, ваш голос учтён
                        </Alert>
                    </>
                }
            </div>
        )
    }
}

export default Voting