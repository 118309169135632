import React, { Component } from 'react'
import Navigation from "./Navigation";
import MiddleMenu from "./MiddleMenu";

class SiteHead extends Component {
    render() {
        return (
            <div className='d-lg-block fixed-top'>
                <Navigation sponsors={this.props.sponsors} />
                <MiddleMenu />
            </div>
        );
    }
}

export default SiteHead
