import React, { Component } from 'react';
import ExternalPost from "./ExternalPost";
import Post from "./Post";
import GalleryItem from "./GalleryItem";
import Video from "./Video";

class SearchResultItem extends Component {

    constructor(props) {
        super(props);
        this.getResultComponent = this.getResultComponent.bind(this);
    }

    getResultComponent(item, cssClass = 'col-md-6 col-xl-3 mb-4') {
        if (item.type === 'Post') {
            let obj = item.object;
            switch (obj.post_type) {
                case 'external':
                    return <ExternalPost key={obj.id} cssClass={cssClass} post={obj.data.attributes} />
                default:
                    return <Post key={obj.id} cssClass={cssClass} post={obj.data.attributes} />
            }
        }
        if (item.type === 'Gallery') {
            let obj = item.object;
            return <GalleryItem key={obj.id} gallery={obj.data.attributes} />
        }
        if (item.type === 'Video') {
            let obj = item.object;
            return <Video cssClass='col-xl-4' video={obj.data.attributes} key={obj.id} />
        }
    }

    render() {
        return (
            <>
                {this.getResultComponent(this.props.search_object)}
            </>
        );
    }
}

export default SearchResultItem