import React, { Component } from 'react'
import Footer from "./Footer";
import api from '../config/api';
import { getSponsors, parseSponsors } from '../requests/apiCalls';
import axios from "axios";
import SiteHead from "./SiteHead";
import MatchCenterHeader from "./MatchCenterHeader";
import MatchCenterInfo from "./MatchCenterInfo";
import LoadingSpinner from "./LoadingSpinner";

class MatchCenter extends Component {

    constructor(props) {
        super(props)
        this.state = {
            id: this.props.match.params.id,
            loading: true,
            game: null,
            sponsors: []
        }
    }

    getGame() {
        return api.get(`/api/v1/games/${this.state.id}`)
    }

    componentDidMount() {
        axios.all([this.getGame(), getSponsors()])
            .then(axios.spread((game_data, sponsors) => {
                this.setState({ game: game_data.data.data.attributes, sponsors: parseSponsors(sponsors.data), loading: false })
            }));
    }

    getScorers(data, home_roster, guest_roster) {
        let home_list = [];
        let guest_list = [];
        let goals = [];

        for (let liveItem of data) {
            let type = liveItem.live_item_type;
            if (type === 'goal') {
                goals.push(liveItem);
            }
        }

        for (let goal of goals) {
            for (let player of home_roster) {
                if (player.player_name.includes(goal.affected_people)) {
                    home_list.push(goal);
                }
            }
            for (let player of guest_roster) {
                if (player.player_name.includes(goal.affected_people)) {
                    guest_list.push(goal);
                }
            }
        }
        return [home_list, guest_list]
    }

    render() {
        const { loading, game, sponsors} = this.state;
        return (
            <>
                {loading ? <LoadingSpinner/> :
                    <>
                        <SiteHead sponsors={sponsors}/>
                        <MatchCenterHeader goals={this.getScorers(game.live_items, game.guest_roster_data, game.home_roster_data)} game={game} />
                        <div className="container">
                            <div className="justify-content-center align-items-center">
                                <div className="post-body">
                                    <MatchCenterInfo game={game}/>
                                </div>
                            </div>
                        </div>
                        <Footer sponsors={sponsors} />
                    </>
                }
            </>
        );
    }
}

export default MatchCenter