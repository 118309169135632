import React, { Component } from 'react'
import SpecialProject from "./SpecialProject";

class SpecialProjectsContainer extends Component {
    render() {
        return (
            <div className='container-fluid'>
                {this.props.projects.map((project, index) => {
                    return <SpecialProject key={project.id} index={index} project={project.attributes}/>
                })}
            </div>
        );
    }
}

export default SpecialProjectsContainer