import React, { Component } from 'react'
import LoadingSpinner from "./LoadingSpinner";
import PostsContainer from "./PostsContainer";
import MainSlider from "./MainSlider";
import Footer from "./Footer";
import MainGame from "./MainGame";
import ShopContainer from "./ShopContainer";
import BannerContainer from "./BannerContainer";
import SiteHead from "./SiteHead";
import SpecialProjectsContainer from "./SpecialProjectsContainer";
import NowContainer from "./NowContainer";
import Banner from "./Banner";
import Button from "reactstrap/es/Button";
import api from "../config/api";
import axios from "axios";

class SiteBody extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loaded_posts: [],
            hasMore: true,
            loading: true,
            nextStamp: this.props.nextStamp,
            showPopup: false
        };

        this.loadMore = this.loadMore.bind(this);
    }

		togglePopup() {
			this.setState({
				showPopup: !this.state.showPopup
			});
		}

    loadMore() {
        this.callPosts()
    }

    getPosts() {
        return api.get('/api/v1/posts.json?from=' + (this.state.nextStamp ? this.state.nextStamp : this.props.nextStamp))
    }

    callPosts() {
        axios.all([this.getPosts()])
            .then(axios.spread((posts) => {
                let data = posts.data.posts.data;
                let loadedPosts = this.props.new_posts;
                Array.prototype.push.apply(loadedPosts, data);
                let nextStamp = data[data.length - 1] ? data[data.length - 1].attributes.timestamp : null;
                this.setState({ loaded_posts: loadedPosts, nextStamp: nextStamp});
                if(nextStamp === null) {
                  this.setState({ hasMore: false })
                }
            }));
    }

    getObj(post) {
      return (post !== null ? JSON.parse(post) : this.props.new_posts[0].attributes)
    }

    render() {
        return (
            <>
                {this.props.loading ? <LoadingSpinner/> :
                    <>
                        <SiteHead sponsors={this.props.sponsors}/>
												{this.state.showPopup ?
													<Banner
														banner={this.props.banner}
														closePopup={this.togglePopup.bind(this)}
													/>
													: null
												}
                        <MainSlider posts={this.props.slides} />
                        <div className="container pb-1 banner_container">
                          <div className="text-center pb-4">
                            <a href='https://www.rubin-kazan.ru/pages/fancard'><img className='img-fluid' src="/images/first.jpg"/></a>
                          </div>
                        </div>
                        <PostsContainer posts={this.props.posts} />
                        {this.props.game &&
                          <MainGame game={this.props.game} />
                        }
                        {this.props.fonbet_banner ?
                          <BannerContainer />
                          : null
                        }
                        <NowContainer post={this.getObj(this.props.mainPost)} voted={this.props.voted} voting={this.props.voting} standings={this.props.standings}/>
                        <ShopContainer shop_items={this.props.shop_items} />
                        <SpecialProjectsContainer projects={this.props.projects} />
                        <PostsContainer posts={this.state.loaded_posts.length === 0 ? this.props.new_posts : this.state.loaded_posts} />
                        {(this.state.hasMore && this.props.new_posts.length > 0) &&
                        <div className='container text-center'>
                          <Button onClick={this.loadMore} className='mb-4 load_more_btn'>ЗАГРУЗИТЬ ЕЩЕ</Button>
                        </div>}
                        <Footer sponsors={this.props.sponsors} />
                    </>
                }
            </>
        );
    }
}

export default SiteBody
