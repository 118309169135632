/* eslint-disable */

import React from "react";
import moment from "moment-timezone";
import {Link} from "react-router-dom";

class GameBlock extends React.Component {

    render() {
        return (
            <div className='container-fluid text-center bg-img-rubin pt-1 pb-3'>
                <div className="row">
                    <div className='col-xl-4 col-md-4 col-4 align-self-center'>
                        <img className='img-fluid mx-auto d-block' alt={this.props.game.home_team_name} src={this.props.game.home_team_logo} />
                    </div>
                    <div className='col-xl-4 col-md-4 col-4 text-center text-game-date'>
                        <img className='img-fluid mx-auto d-block' alt={this.props.game.tournament_name} src={this.props.game.tournament_logo} />
                        <p>{this.props.game.stadium ? this.props.game.stadium : 'Уточняется'}</p>
                            <>
                                <p className='game_date'>{this.props.game.home_goals} - {this.props.game.guest_goals}</p>
                                <p className='game_time'>&nbsp;</p>
                                <p className='game_date'>{moment(this.props.game.game_date).format('DD MMMM')}</p>
                                {moment(this.props.game.game_date).tz("Europe/Moscow").format('HH:mm') !== '00:00' ?
                                    <p className='game_time'>{moment(this.props.game.game_date).tz("Europe/Moscow").format('HH:mm')}</p>
                                    : ''
                                }
                            </>
                        {((this.props.game.home_goals == "" && this.props.game.guest_goals == "") || this.props.game.game_ended) ?
                            <Link className='btn buy_button' to={`/games/${this.props.game.slugged}`}>МАТЧ ЦЕНТР</Link>
														: ''
                        }
												{ this.props.game.tickets_url &&
													<div className='mt-3'><a href={this.props.game.tickets_url} className='btn buy_button'>КУПИТЬ БИЛЕТ</a></div>
												}
                    </div>
                    <div className='col-xl-4 col-md-4 col-4 align-self-center'>
                        <img className='img-fluid mx-auto d-block' alt={this.props.game.guest_team_name} src={this.props.game.guest_team_logo} />
                    </div>
                </div>
            </div>
        );
    }
}

export default GameBlock