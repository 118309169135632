import React, { Component } from 'react'
import api from '../config/api';
import axios from 'axios';
import { getSponsors, parseSponsors } from '../requests/apiCalls';
import NewsHubBody from "./NewsHubBody";
import RubinPagination from "./RubinPagination";

class NewsHub extends Component {

    constructor(props) {
        super(props);
        this.state = {
            type: this.props.match.params.team_type,
            loading: true,
            posts: [],
            sponsors: [],
            page: 1,
			total: 52,
			tag: window.document.URL.toString().split('?tag=')[1]
        };
        this.onPageChange = this.onPageChange.bind(this);
    }

    onPageChange(page) {
        this.setState({loading: true});
        axios.all([this.getPosts(page)])
            .then(axios.spread((posts) => {
                this.setState({ page: posts.data.page, total: posts.data.pages, posts: posts.data.posts.data, loading: false })
            }));
    }

    getPosts(page = 1) {
        let team_type =  this.props.match.params.team_type;
        let page_params = { page: page };
        if(this.state.tag) {
					page_params = { tag: this.state.tag, page: page };
				}
				if(team_type) {
            page_params = { type: team_type, page: page };
        }
        return api.get('/api/v1/posts/hub.json', { params: page_params })
    }

    componentDidMount() {
        axios.all([this.getPosts(), getSponsors()])
            .then(axios.spread((posts, sponsors) => {
                this.setState({ page: posts.data.page, total: posts.data.pages, posts: posts.data.posts.data, sponsors: parseSponsors(sponsors.data), loading: false })
            }));
    }

    render() {
        const { loading, posts, sponsors, page, total } = this.state;
        return (
            <>
                <NewsHubBody pagination={<RubinPagination onPageChange={this.onPageChange.bind(this)} page={page} total={total} />} posts={posts} sponsors={sponsors} loading={loading} />
            </>
        );
    }
}

export default NewsHub