import React, { Component } from 'react'
import api from '../config/api';
import axios from 'axios';
import {isMobile} from 'react-device-detect';
import LoadingSpinner from "./LoadingSpinner";
import SiteHead from "./SiteHead";
import Banner from "./Banner";
import Cookies from 'universal-cookie';

class Staging extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            posts: [],
            banner: null,
            showPopup: this.showPopup()
        }
    }

    showPopup() {
        const cookies = new Cookies();
        return !cookies.get('disable_popup');
    }

    togglePopup() {
        //const cookies = new Cookies();
        //cookies.set("disable_popup", true, { path: '/' });
        this.setState({
            showPopup: !this.state.showPopup
        });
    }

    getPosts() {
        return api.get('/api/v1/posts.json', { params: { m: isMobile } })
    }

    componentDidMount() {
        axios.all([this.getPosts()])
            .then(axios.spread((posts) => {
							const banner_attributes = posts.data.banner.data ? posts.data.banner.data.attributes : null
              this.setState({ banner: banner_attributes, loading: false })
            }));
    }

    render() {
        const { banner, loading } = this.state;
        return (
            <>
                {loading ? <LoadingSpinner/> :
                    <>
                        {/* <SiteHead sponsors={sponsors}/> */}
                        {this.state.banner && this.state.showPopup ?
                            <Banner
                                banner={banner}
                                closePopup={this.togglePopup.bind(this)}
                            />
                            : null
                        }
                    </>
                }
            </>
        );
    }
}

export default Staging

