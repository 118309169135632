import React, { Component } from 'react'
import moment from "moment";
import Tag from "./Tag";
import Gallery from "./Gallery";
import {BrowserView, MobileView} from "react-device-detect";

class GalleryBody extends Component {
    render () {
        return(
            <>
                <div className='container content long_top'>
                    <main role="main">
											<div className='container'>
													{this.props.gallery_slides.length > 0 &&
													<>
														<BrowserView>
															<div className='gallery_wrapper'>
																<Gallery gallery_slides={this.props.gallery_slides} />
															</div>
														</BrowserView>
														<MobileView>
															<Gallery sidebar='bottom' gallery_slides={this.props.gallery_slides} />
														</MobileView>
													</>
													}
											</div>
											<div className='row min-vh-50'>
													<div className="p-3 p-md-5 rounded">
															<h2 className="blog-post-title"> {this.props.gallery.title}</h2>
															<p className="blog-video-meta">{moment(this.props.gallery.created_at).format('DD MMM YYYY')}</p>
															<p>{this.props.gallery.description}</p>
															<div className="tag_list">
																	{this.props.gallery.tags && this.props.gallery.tags.map((tag, index) => {
																			return (<Tag tag={tag.name} key={index} />)
																	})}
															</div>
													</div>
											</div>
                    </main>
                </div>
            </>
        )
    }
}

export default GalleryBody