import React, { Component } from 'react'
import LoadingSpinner from "./LoadingSpinner";
import api from "../config/api";
import axios from "axios";
import {getSponsors, parseSponsors} from "../requests/apiCalls";
import Footer from "./Footer";
import SiteHead from "./SiteHead";
import StandingsFull from "./StandingsFull";

class StandingsPage extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            standings: [],
            sponsors: []
        }
    }

    getStandings() {
        let team_type =  this.props.match.params.team_type;
        return api.get('/api/v1/teams/' + team_type + '/standings.json')
    }

    componentDidMount() {
        axios.all([this.getStandings(), getSponsors()])
            .then(axios.spread((standings, sponsors) => {
                this.setState({ standings: standings.data.data, loading: false, sponsors: parseSponsors(sponsors.data) })
            }));
    }

    render() {
        const { loading, standings, sponsors } = this.state;
        return(
            <>
							{loading ? <LoadingSpinner /> :
								<>
										<SiteHead sponsors={sponsors}/>
										<StandingsFull standings={standings} />
										<Footer sponsors={sponsors} />
								</>}
            </>
        )
    }
}

export default StandingsPage