import React, { Component } from 'react'
import LoadingSpinner from "./LoadingSpinner";
import moment from "moment";
import 'moment/locale/ru';
import Tag from "./Tag";

class PlayerProfile extends Component {

    rawMarkup(text) {
        let rawMarkup = text
        return { __html: rawMarkup };
    }

    renderSocialLink(linkType, profileUrl) {
        if(profileUrl) {
            return <a rel="noopener noreferrer" target='_blank' className="nav-link py-0" href={'https://' + linkType + '.com/' + profileUrl}><i className={'fa fa-' + linkType} /></a>
        }
    }

    render () {
        return(
            <>
                <div className='content'>
                    <section>
                        {this.props.loading ? <LoadingSpinner /> :
                            <>
                                <div className='container-fluid-profile'>
                                    <div className="row">
                                            <div className="col-md-6 col-xl-6 text-center">
                                              <img className='img-fluid' alt={this.props.player_profile.first_name + " " + this.props.player_profile.last_name} src={this.props.player_profile.photo && this.props.player_profile.photo.url != null ? this.props.player_profile.photo.player_preview.url : '/images/player_default.png'} />
																							{this.props.player_profile.full_size_photo.url &&
																							<a className='d-block' href={this.props.player_profile.full_size_photo.url} download>
																							Скачать фото в хорошем качестве
																							</a>
																							}
																						</div>

                                            <div className="col-md-6 col-xl-6 text-left">
                                                <div className='row'>
                                                    <div className='col-md-12'>
																												{this.props.player_profile.number &&
																													<h3 className='d-inline-block player_profile_number'>{this.props.player_profile.number}</h3>
																												}
                                                        <h3 className="d-inline-block player_profile_name">{this.props.player_profile.first_name} {this.props.player_profile.last_name}</h3>
                                                    </div>
                                                </div>
                                                <div className='row mb-3'>
																									<div className='col-md-12'><h5>{this.props.player_profile.club_position ? this.props.player_profile.club_position : ''}</h5></div>
																									<div className='col-md-12'><h5>{this.props.player_profile.squad ? this.props.player_profile.squad.name : ''}</h5></div>
																									</div>
                                                <hr/>
                                                {this.props.player_profile.number ?
													<div className='row'>
                                                        <div className='row'>
                                                            <div className='col-md-3 col-2'>
                                                                <div className='col-md-12 text-center'>
                                                                    <p className='pt-3 icon_text'><i title='Игры сыграно'
                                                                        className="fa fa-calendar-check-o"
                                                                        aria-hidden="true" /></p>
                                                                    <span>{this.props.player_profile.games_played}</span>
                                                                </div>
                                                                <div className='col-md-12 text-center'>
                                                                    <p className='pt-3 icon_text'><i title='Минут сыграно'
                                                                        className="fa fa-clock-o"
                                                                        aria-hidden="true"/></p>
                                                                    <span>{this.props.player_profile.minutes_played}</span>
                                                                </div>
                                                            </div>
                                                            <div className='col-md-3 col-2'>
																																{ this.props.player_profile.player_type !== 'Вратарь' ?
																																	<>
																																		<div className='col-md-12 text-center'>
																																				<p className='pt-3 icon_text'><i title='Голы'
																																						className="fa fa-futbol-o"
																																						aria-hidden="true"/></p>
																																				<span>{this.props.player_profile.goals || 0}</span>
																																		</div>
																																		<div className='col-md-12 text-center'>
																																				<p className='pt-3 icon_text'><i title='Передач'
																																						className="fa fa-plus-circle"
																																						aria-hidden="true"/></p>
																																				<span>{this.props.player_profile.assists || 0}</span>
																																		</div>
																																	</>
																																:
																																	<>
																																		<div className='col-md-12 text-center'>
																																				<p className='pt-3 icon_text'><i title='Пропущено голов'
																																						className="fa fa-futbol-o"
																																						aria-hidden="true"/></p>
																																				<span>{this.props.player_profile.missed_goals}</span>
																																		</div>
																																		<div className='col-md-12 text-center'>
																																				<p className='pt-3 icon_text'><i title='Сухие матчи'
																																						className="fa fa-check"
																																						aria-hidden="true"/></p>
																																				<span>{this.props.player_profile.cleansheets}</span>
																																		</div>
																																	</>
																																}
                                                            </div>
                                                            <div className='col-md-3 col-2'>
                                                                <div className='col-md-12 text-center'>
                                                                    <p className='pt-3 icon_text'><i title='Рост'
                                                                        className="fa fa-arrows-v"
                                                                        aria-hidden="true"/></p>
                                                                    <span>{this.props.player_profile.height}</span>
                                                                </div>
                                                                <div className='col-md-12 text-center'>
                                                                    <p className='pt-3 icon_text'><i title='Вес'
                                                                        className="fa fa-balance-scale"
                                                                        aria-hidden="true"/></p>
                                                                    <span>{this.props.player_profile.weight}</span>
                                                                </div>
                                                            </div>
                                                            <div className='col-md-3 col-2'>
                                                                <div className='col-md-12 text-center'>
                                                                    <p className='pt-3 icon_text'><i title='Дата рождения'
                                                                        className="fa fa-calendar"
                                                                        aria-hidden="true"/></p>
                                                                    <span>{moment(this.props.player_profile.birthdate).format("DD/MM/YYYY")}</span>
                                                                </div>
                                                                <div className='col-md-12 text-center'>
                                                                    <p className='pt-3 icon_text'><i title='Место рождения'
                                                                        className="fa fa-globe" aria-hidden="true"/></p>
                                                                    <span>{this.props.player_profile.birthplace}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
																								:
																									<div className='row'>
																										<div className='col-md-3 col-4'>
																												<div className='col-md-6 text-center'>
																														<p className='pt-3 icon_text'><i
																																className="fa fa-calendar"
																																aria-hidden="true"/></p>
																														<span>{moment(this.props.player_profile.birthdate).format("DD/MM/YYYY")}</span>
																												</div>
																												<div className='col-md-6 text-center'>
																												</div>
																										</div>
																										<div className='col-md-3 col-4'>
																												<div className='col-md-6 text-center'>
																														<p className='pt-3 icon_text'><i
																																className="fa fa-globe" aria-hidden="true"/></p>
																														<span>{this.props.player_profile.birthplace}</span>
																												</div>
																												<div className='col-md-6 text-center'>
																												</div>
																										</div>
																									</div>
																								}
																								<hr/><div className='row pt-3 rubin-red'>
                                                    {this.renderSocialLink('facebook', this.props.player_profile.facebook)}
                                                    {this.renderSocialLink('twitter', this.props.player_profile.twitter)}
                                                    {this.renderSocialLink('instagram', this.props.player_profile.instagram)}
                                                    {this.renderSocialLink('vk', this.props.player_profile.vkontakte)}
                                                </div>
                                            </div>
                                    </div>
                                </div>
                                <div className="row body_text_class mx-auto pt-5 mb-5">
                                    <div className="col-md-12 blog-main">
                                        <div className="blog-post">
                                            <div className="post_body_text" dangerouslySetInnerHTML={this.rawMarkup(this.props.player_profile.bio)}/>
                                        </div>
                                        <div className="tag_list">
                                            {this.props.player_profile.tags && this.props.player_profile.tags.map((tag, index) => {
                                                return (<Tag tag={tag.name} key={tag.id} />)
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </>}
                    </section>
                </div>
            </>
        )
    }
}

export default PlayerProfile