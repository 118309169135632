import React, { Component } from 'react'
import { Table } from 'reactstrap';
import {Link} from "react-router-dom";

class Standings extends Component {
    render () {
        return(
            <>
                <div className="table-responsive">
                    <div className={`standings-scrollbar ${(this.props.voting ? 'table_standing_size' : '')}`}>
                        <div className='container table_head'>
                            <div className='table_first'>Турнирная таблица</div>
                        </div>
                        <Table className='table_standings' borderless>
                            <thead className='standings_head'>
                                <tr className='head_th'>
                                    <th></th>
                                    <th>Команда</th>
                                    <th>М</th>
                                    <th>О</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.standings.map((standing, index) => {
                                    return <tr key={standing.id} className={'main_team_' + standing.is_main}>
                                        <td>{index + 1}</td>
                                        <td>{standing.name}</td>
                                        <td>{standing.games}</td>
                                        <td>{standing.points}</td>
                                    </tr>
                                })}
                            </tbody>
                        </Table>
                    </div>
                    <span><Link className="main_team_true" to={`/rubin/standings`}>Полная таблица</Link></span>
                </div>
            </>
        )
    }
}

export default Standings