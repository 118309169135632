import React from 'react';
import SponsorsContainer from "./SponsorsContainer";
import Cookies from 'universal-cookie';

class Footer extends React.Component {

    constructor(props) {
      super(props);
      this.setMobile = this.setMobile.bind(this);
    };

    setMobile() {
      const cookies = new Cookies();
      cookies.remove('desktop');
      window.location.reload();
    }
    
    render() {
        return (
            <footer className="page-footer font-small mdb-color lighten-3 pt-3 bg-img-rubin">
                <div className="container pb-3">
                    <SponsorsContainer sponsors={this.props.sponsors}/>
                    <div className="text-center">
                      <p className='link_full' onClick={this.setMobile}>мобильная версия сайта</p>
                    </div>
                </div>
            </footer>
        )
    }
}

export default Footer;


