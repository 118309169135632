/* eslint-disable */

import React from "react";
import moment from "moment-timezone";
import {Link} from "react-router-dom";

class UpcomingGame extends React.Component {

    render() {
        return (
            <div className='col-xl-6 col-md-6 align-self-center text-center pt-3'>
                <h5 className='game_header'>{this.props.index === 0 ? (this.props.games_size == 1 ? 'Следующий матч' : 'Последний матч') : 'Следующий матч'}</h5>
                <div className='container-fluid text-center bg-img-rubin pb-3'>
                    <div className="row">
                        <div className='col-xl-4 col-md-4 col-4 align-self-center'>
                            <img className='img-fluid mx-auto d-block' alt={this.props.game.home_team_name} src={this.props.game.home_team_logo} />
                        </div>
                        <div className='col-xl-4 col-md-4 col-4 text-center text-game-date'>
                            <img className='img-fluid mx-auto d-block' alt={this.props.game.tournament_name} src={this.props.game.tournament_logo} />
                            <p>{this.props.game.stadium ? this.props.game.stadium : 'Уточняется'}</p>
                            {this.props.index === 0 ?
                                <>
                                    <p className='game_date'>{this.props.game.home_goals} - {this.props.game.guest_goals}</p>
                                    <p className='game_time'>&nbsp;</p>
                                </>
                                :
                                <>
                                    <p className='game_date'>{this.props.game.game_date ? moment(this.props.game.game_date).format('DD MMMM') : <>&nbsp;</>}</p>
                                    <p className='game_time'>{this.props.game.game_date ? moment(this.props.game.game_date).tz("Europe/Moscow").format('HH:mm') : <>&nbsp;</>}</p>
                                </>
                            }
                            {(this.props.game.home_goals !== null && this.props.game.guest_goals !== null) ?
                                <Link className='btn buy_button' to={`/games/${this.props.game.slugged}`}>МАТЧ ЦЕНТР</Link>
                                :
                                <>
																	{ this.props.game.tickets_url &&
																		<a href={this.props.game.tickets_url} className='btn buy_button'>КУПИТЬ БИЛЕТ</a>
																	}

																	{
																		(!this.props.game.home_goals && !this.props.game.guest_goals && !this.props.game.tickets_url) ?
																		<a className='btn buy_button' href='javascript:void(0)'>МАТЧ НЕ НАЧАЛСЯ</a>
																		: ''
																	}

                                    {this.props.game.home_goals && this.props.game.guest_goals &&
                                        <Link className='btn buy_button' to={`/games/${this.props.game.slugged}`}>МАТЧ ЦЕНТР</Link>
                                    }
									    					</>
                            }
                        </div>
                        <div className='col-xl-4 col-md-4 col-4 align-self-center'>
                            <img className='img-fluid mx-auto d-block' alt={this.props.game.guest_team_name} src={this.props.game.guest_team_logo} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default UpcomingGame