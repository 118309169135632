/* eslint-disable */

import React, { Component } from 'react'
import { ListGroupItem, Badge } from 'reactstrap';

class LiveItem extends Component {

    renderIcon(action_type) {
        if (action_type === 'goal') {
            return <div className='d-inline-block'><i className="fa fa-futbol-o" aria-hidden="true" /></div>
        }
        if (action_type === 'yellow_card') {
            return <div className="d-inline-block card_soccer yellow" />
        }
        if (action_type === 'red_card') {
            return <div className="d-inline-block card_soccer red" />
        }
    }

    rawMarkup(str){
        return { __html: str };
    }

    renderAffectedPeople(affected_people) {
        if (affected_people) {
            let data = affected_people.split(',');
            if (data.length > 1) {
                return `<span class="player_out">${data[0]}</span> ` +  `<i class="fa fa-exchange" aria-hidden="true"></i>` + ` <span class="player_in">${data[1]}</span>`;
            } else {
                return affected_people
            }
        }
    }

    render() {
        return (
            <>
                <ListGroupItem className="justify-content-between">
                    <Badge pill>{this.props.live_item.minute}'</Badge> <div className='live_item_text'><span className='player_action' dangerouslySetInnerHTML={this.rawMarkup(this.renderAffectedPeople(this.props.live_item.affected_people))}></span> {this.props.live_item.comment} {this.renderIcon(this.props.live_item.live_item_type)}</div>
                </ListGroupItem>
            </>
        );
    }
}

export default LiveItem