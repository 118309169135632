import React, { Component } from 'react';
import Post from '../components/Post';
import LoadingSpinner from "./LoadingSpinner";
import ExternalPost from "../components/ExternalPost";

class PostsContainer extends Component {

    constructor(props) {
        super(props)
        this.getPostComponent = this.getPostComponent.bind(this)
    }

    getPostComponent(post, cssClass = 'col-md-6 col-xl-3 mb-4') {
        switch (post.attributes.post_type) {
            case 'external':
                return <ExternalPost key={post.id} cssClass={cssClass} post={post.attributes} />
            default:
                return <Post key={post.id} cssClass={cssClass} post={post.attributes} />
        }
    }

    render() {
        return (
            <div className="container mt-3 posts_container">
                {this.props.loading ? <LoadingSpinner /> :
                    <>
                        <div className={'row row-cards'}>
                            {this.props.posts.map((post) => {
                                return this.getPostComponent(post)
                            })}
                        </div>
                    </>}
            </div>
        );
    }
}

export default PostsContainer