import axios from 'axios';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const api = axios.create({
    baseURL: process.env.REACT_APP_CONNECT_STRING
});

axios.defaults.params = {};
axios.defaults.params[ 'locale' ] = cookies.get('locale');

export default api;