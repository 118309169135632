import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ShopItem from "./ShopItem";

class ShopContainer extends React.Component {

    render() {
        let settings = {
            dots: true,
            infinite: false,
            arrows: true,
            speed: 500,
            slidesToShow: 5,
            slidesToScroll: 5,
            swipeToSlide: true,
            initialSlide: 0,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: false
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        initialSlide: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        centerMode: true,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        return (
            <div className="container-fluid mb-5" key={'custom_shop_items'}>
                <Slider {...settings} className={'slider_shop_items'} ref={'slider_shop_items'} >
                    {this.props.shop_items.map((shop_item) => {
                        return (<ShopItem shop_item={shop_item.attributes} key={shop_item.id} />)
                    })}
                </Slider>
            </div>
        );
    }
}

export default ShopContainer