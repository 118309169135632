import React, { Component } from 'react'
import axios from "axios";
import api from "../config/api";

class VotingPlayer extends Component {

    constructor(props) {
        super(props)
        this.handleClick = this.handleClick.bind(this);
        this.handleFalse = this.handleFalse.bind(this);
    }

    callVote() {
        return api.post("/api/v1/votings/" + this.props.voting_id + "/voting_items/" + this.props.voting_item.id + "/vote")
    }

    delay(ms) {
        return new Promise(function (resolve, reject) {
            setTimeout(resolve, ms);
        });
    }

    handleClick() {
        this.props.setLoading(true);
        var photo = this.props.voting_item.player_photo != null ? this.props.voting_item.player_photo : '/images/player_default.png'
        this.props.setPhoto(photo)
        axios.all([this.callVote()])
            .then(axios.spread((data) => {
                this.delay(2000)
                    .then(() => {
                        this.props.setVoted(data);
                        this.props.setLoading(false);
                    });
            }));
    }

    handleFalse() {
        return false
    }

    splitName(fullname) {
        return fullname.split(' ')
    }

    render () {
        let name = this.splitName(this.props.voting_item.player_name)
        return(
            <div data-name={name} className='col-md-3 mb-3' onClick={!this.props.voted ? this.handleClick : this.handleFalse}>
                <div className='row no-gutters'>
                    <div className="col-auto">
                        <img width='70' alt={this.props.voting_item.option_text} src={this.props.voting_item.player_photo != null ? this.props.voting_item.player_photo : '/images/player_default.png'} />
                    </div>
                    <div className="col">
                        <div className="card-block px-2">
                            <h6 className='custom_vote_item cward-title'>{name[0]}</h6>
                            <h6 className='custom_vote_item cward-title'>{name[1]}</h6>
                            {this.props.voted && this.props.voting_item.percentage >= 0 ?
                                <b className='card-text color_number'>{this.props.voting_item.percentage}%</b>
                                :
                                <b className='card-text color_number'>№{this.props.voting_item.player_number}</b>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default VotingPlayer