import React, { Component } from 'react'
import VotingPlayer from "./VotingPlayer";
import VotingLoadingSpinner from "./VotingLoadingSpinner";
import { Collapse } from 'reactstrap';
import {
    isMobile,
    BrowserView,
    MobileView
} from "react-device-detect";

class VotingWidget extends Component {

    constructor(props) {
        super(props)
        this.state = {
            voted: false,
            loading: false,
            voting_items: [],
            voting_photo: null,
            collapse: false
        }
        this.toggle = this.toggle.bind(this);
        this.setPhoto = this.setPhoto.bind(this);
        this.getVoted = this.getVoted.bind(this);
        this.getLoading = this.getLoading.bind(this);
    }

    toggle() {
        this.setState(state => ({ collapse: !state.collapse }));
    }

    getVoted(data) {
        this.setState({ voting_items: data.data.voting_items, voted: true })
    }

    setPhoto(url) {
        this.setState({ voting_photo: url })
    }

    getLoading(state) {
        this.setState({ loading: state })
    }

    componentWillMount() {
        this.setState({ loading: this.props.loading, voting_items: this.props.voting.voting_items, voted: this.props.voted })
    }

    render () {
        return(
            <div className='row'>
                {this.state.loading ? <VotingLoadingSpinner voting_photo={this.state.voting_photo}/> :
                    <>
                        <BrowserView>
                            <h4 className='mb-4 poll_title'>{this.props.voting.title}</h4>
                        </BrowserView>
                        <MobileView>
                            <h4 onClick={this.toggle} className='mb-4 poll_title'>{this.props.voting.title} <span className='rubin-red'>&darr;</span></h4>
                        </MobileView>
                        <Collapse isOpen={isMobile ? this.state.collapse : true}>
                        <div className='row'>
                                {this.state.voting_items.data.map((voting_item) => {
                                    let voting_item_model = voting_item.attributes
                                    return <VotingPlayer voted={this.state.voted}
                                                         setLoading={this.getLoading}
                                                         setVoted={this.getVoted}
                                                         setPhoto={this.setPhoto}
                                                         voting_id={this.props.voting.id}
                                                         voting_item={voting_item_model}
                                                         key={voting_item_model.id} />
                                })}
                        </div>
                        </Collapse>

                    </>
                }
            </div>
        )
    }
}

export default VotingWidget
