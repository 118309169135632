import React, { Component } from 'react'
import { Card, CardImg, CardBody, CardTitle, CardFooter } from 'reactstrap';
import moment from 'moment'

class ExternalPost extends Component {
    render () {
        return(
            <div className={this.props.cssClass}>
                <a rel="noopener noreferrer" target='_blank' className="post_link" href={`${this.props.post.external_link}`}>
                    <Card className="main_card h-100">
                        <CardImg top src={this.props.post.cover.post_preview.url != null ? this.props.post.cover.post_preview.url : "/images/cover_default.png"} alt={this.props.post.title} />
                        <CardBody>
                            <CardTitle className="post-card-title">{this.props.post.title}</CardTitle>
                        </CardBody>
                        <CardFooter>
                            {moment(this.props.post.created_at).tz('Europe/Moscow').format("DD.MM.YYYY в HH:mm")}
                            {this.props.post.post_type === 'external' &&
                                <i className="fa fa-camera float-right"/>
                            }
                        </CardFooter>
                    </Card>
                </a>
            </div>
        )
    }
}

export default ExternalPost




