import React, { Component } from 'react'
import LoadingSpinner from "./LoadingSpinner";
import api from "../config/api";
import axios from "axios";
import {getSponsors, parseSponsors, getSeasons} from "../requests/apiCalls";
import Footer from "./Footer";
import SiteHead from "./SiteHead";
import GameBlock from "./GameBlock";
import UpcomingGame from "./UpcomingGame";
import "react-picker/css/picker.css";

class CalendarPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tournament_id: this.props.match.params.tournament_id,
            team_id: this.props.match.params.team_id,
            loading: true,
            upcoming: [],
            seasons: [],
            games: [],
            sponsors: [],
            selectedSeason: 0
        }
    }

    getUpcoming() {
        return api.get(`/api/v1/games/${this.state.team_id}/upcoming.json`)
    }

    getGames(season_id) {
        let select = this.state.selectedSeason
        if (season_id) {
            select = season_id
        }
        return api.get(`/api/v1/${this.state.team_id}/games.json?id=${select}`)
    }

    getSeasons() {
        return api.get(`/api/v1/seasons.json`)
    }

    handleChange(season_id) {
        this.setState({ selectedSeason: season_id, loading: true })
        axios.all([this.getGames(season_id)])
            .then(axios.spread((games) => {
                this.setState({ games: games.data.data, loading: false})
            }));
    }

    componentDidMount() {
        axios.all([this.getUpcoming(), this.getGames(), getSponsors(), getSeasons()])
            .then(axios.spread((upcoming, games, sponsors, seasons) => {
                this.setState({ upcoming: upcoming.data.data, games: games.data.data, loading: false, sponsors: parseSponsors(sponsors.data), seasons: seasons.data.data, selectedSeason: seasons.data.data[0].id })
            }));
    }

    render() {
        const { loading, upcoming, games, sponsors } = this.state;
        return(
            <>
                {loading ? <LoadingSpinner /> :
                    <>
                        <SiteHead sponsors={sponsors}/>
                        <div className='container content mb-5'>
                                    <div className='row mb-3'>
                                        {upcoming.map((game, index) => {
                                            return <UpcomingGame games_size={upcoming.length} index={index} key={game.id} game={game.attributes} />
                                        })}
                                    </div>
                            <br/>
                            <div className='row'>
                                <div className='col-xl-4 col-md-6 align-self-center text-center pt-3'>
                                    <div className='game_header text-left'>
                                        <select value={this.state.selectedSeason} onChange={(val) => this.handleChange(val.target.value)}>
                                        {this.state.seasons.map((option) => (
                                            <option key={option.id} value={option.id}>{option.attributes.name}</option>
                                        ))}
                                        </select>
                                    </div>
                                </div>
                                <div className='col-xl-4 col-md-6 align-self-center text-center pt-3'>
                                    <h5 className='game_header text-center'>Полное расписание</h5>
                                </div>
                                <div className='col-xl-4 col-md-6 align-self-center text-center pt-3'>
                                </div>
                            </div>
                            {games.map((game) => {
                                return <GameBlock key={game.id} game={game.attributes} />
                            })}
                        </div>
                        <Footer sponsors={sponsors} />
                    </>}
            </>
        )
    }
}

export default CalendarPage