import React, { Component } from 'react'
import Video from '../components/Video';
import LoadingSpinner from "./LoadingSpinner";

class SimilarVideosContainer extends Component {
    render() {
        return (
            <div className="container pb-5">
                <h5 className="videos_title">Смотрите далее</h5>

                {this.props.loading ? <LoadingSpinner /> :
                    <div className={'row row-cards'}>
                        {this.props.videos.map((video) => {
                            return <Video cssClass={this.props.cssClass} video={video.attributes} key={video.id} />
                        })}
                    </div>}
            </div>
        );
    }
}

export default SimilarVideosContainer