import React, { Component } from 'react'
import LoadingSpinner from "./LoadingSpinner";
import api from "../config/api";
import axios from "axios";
import {getSponsors, parseSponsors} from "../requests/apiCalls";
import Footer from "./Footer";
import SiteHead from "./SiteHead";
import StatisticsTable from "./StatisticsTable";

class StatisticsPage extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            players: [],
            sponsors: []
        }
    }

    getStandings() {
        let team_type =  this.props.match.params.team_type;
        return api.get('/api/v1/teams/' + team_type + '/statistics.json')
    }

    componentDidMount() {
        axios.all([this.getStandings(), getSponsors()])
            .then(axios.spread((players, sponsors) => {
                this.setState({ players: players.data.data, loading: false, sponsors: parseSponsors(sponsors.data) })
            }));
    }

    render() {
        const { loading, players, sponsors } = this.state;
        return(
            <>
                {loading ? <LoadingSpinner /> :
                    <>
                        <SiteHead sponsors={sponsors}/>
                        <StatisticsTable players={players} />
                        <Footer sponsors={sponsors} />
                    </>}
            </>
        )
    }
}

export default StatisticsPage