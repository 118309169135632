import React, { Component } from 'react'
import LoadingSpinner from "./LoadingSpinner";
import Post from "./Post";

class PlayerNewsContainer extends Component {
    render() {
        return (
            <div className="container pb-5">


                {!this.props.loading && this.props.posts.length > 0 &&
                    <h5 className="videos_title">Тематические новости</h5>
                }

                {this.props.loading ? <LoadingSpinner /> :
                    <div className={'row row-cards'}>
                        {this.props.posts.map((post) => {
                            return <Post post={post.attributes} key={post.id} cssClass={this.props.cssClass} />
                        })}
                    </div>}
            </div>
        );
    }
}

export default PlayerNewsContainer