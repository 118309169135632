/* eslint-disable */

import React, { Component } from 'react'
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, CardTitle, Row, Col, CardBody } from 'reactstrap';
import classnames from 'classnames';
import LiveBoard from "./LiveBoard";

class MatchCenterInfo extends Component {

    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            activeTab: '2'
        };
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    renderAffectedPeople(affected_people, surname, name) {
        if (affected_people) {
            let data = affected_people.split(', ');
            if (data.length > 1) {
                let p1 = data[0];
                let p2 = data[1];

                let p1_splitted = p1.split(' ');
                let p2_splitted = p2.split(' ');

                if(p1_splitted.length > 1 && p1_splitted[0] === name && p1_splitted[1] === surname) {
                    return <span className="player_out"><i className="fa fa-arrow-circle-down" aria-hidden="true" /></span>;
                }

                if(p2_splitted.length > 1 && p2_splitted[0] === name && p2_splitted[1] === surname) {
                    return <span className="player_out"><i className="fa fa-arrow-circle-up" aria-hidden="true" /></span>;
                }

                if(p1 === surname) {
                    return <span className="player_out"><i className="fa fa-arrow-circle-down" aria-hidden="true" /></span>;
                }
                if(p2 === surname) {
                    return <span className="player_out"><i className="fa fa-arrow-circle-up" aria-hidden="true" /></span>;
                }
            }
        }
    }

    renderEvent(surname, data) {
      let playerEvents = [];
      let name_data = surname.split(' ');
      let name = name_data.length > 1 ? name_data[1] : name_data[0];
      let fname = name_data.length > 1 ? name_data[0] : name_data[1];
      for (let playerLiveItem of data) {
          if((playerLiveItem.affected_people.length > 0 && surname.includes(playerLiveItem.affected_people)
              || playerLiveItem.live_item_type === 'sub' && playerLiveItem.affected_people.includes(name))) {
              playerEvents.push(playerLiveItem)
          }
      }
      const sortedData = playerEvents.sort(function(a, b){
          return a.id - b.id;
      });
        return (
            <>
                {sortedData.map(live_item => (
                    <span className='liveEvent' key={live_item.id}>{this.renderIcons(live_item, name, fname)}</span>
                ))}
            </>
        );
    }

    renderIcons(live_item, surname, name) {
      let type = live_item.live_item_type;
      if (type === 'sub') {
          return this.renderAffectedPeople(live_item.affected_people, surname, name)
      }
      if (type === 'goal') {
          return <div className='d-inline-block'><i className="fa fa-futbol-o" aria-hidden="true" /></div>
      }
      if (type === 'yellow_card') {
          return <div className="d-inline-block card_soccer yellow" />
      }
      if (type === 'red_card') {
          return <div className="d-inline-block card_soccer red" />
      }
    }

    renderOption(option) {
        if(option) {
            return `[${option}]`
        }
    }

    getId(player) {
        let id = player.id || Math.floor(Math.random() * 20);
        let playerId = player.player_id || Math.floor(Math.random() * 20);
        return parseInt(id +playerId + player.player_number);
    }

    render() {
        return (
            <div className='min-vh-100 container-fluid'>
                <div>
                    <Nav tabs>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: this.state.activeTab === '1' })}
                                onClick={() => { this.toggle('1'); }}
                            >
                                СОСТАВЫ
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: this.state.activeTab === '2' })}
                                onClick={() => { this.toggle('2'); }}
                            >
                                СОБЫТИЯ
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={this.state.activeTab}>
                        <br/>
                        <TabPane tabId="1">
                            <Row>
                                <Col sm="6">
                                    <Card body>
                                        <CardTitle className='text-center text-uppercase'>
                                            <div className='card_game'></div>
                                            <div className='img_title'><img alt={this.props.home_team_name} width={100} src={this.props.game.home_team_logo}/></div>
                                        </CardTitle>
                                        <CardBody className='text-center text-uppercase game_tab'>
                                            {this.props.game.home_roster_data.length > 0 ? this.props.game.home_roster_data.map((player, index) => {
                                                    return <div className={index === 10 ? 'roster_main' : ''} key={this.getId(player)}><b>{player.player_number}</b> {player.player_name} {this.renderOption(player.player_option)} {this.renderEvent(player.player_name, this.props.game.live_items)}</div>
                                                }) :
                                                <p>НЕТ ДАННЫХ</p>}
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col sm="6">
                                    <Card body>
                                        <CardTitle className='text-center text-uppercase'>
                                            <div className='card_game'></div>
                                            <div className='img_title'><img alt={this.props.guest_team_name} width={100} src={this.props.game.guest_team_logo}/></div>
                                        </CardTitle>
                                        <CardBody className='text-center text-uppercase game_tab'>
                                                {this.props.game.guest_roster_data.length > 0 ? this.props.game.guest_roster_data.map((player, index) => {
                                                    return <div className={index === 10 ? 'roster_main' : ''} key={this.getId(player)}><b>{player.player_number}</b> {player.player_name} {this.renderOption(player.player_option)} {this.renderEvent(player.player_name, this.props.game.live_items)}</div>
                                                }) :
                                                <p>НЕТ ДАННЫХ</p>}
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tabId="2">
                            <Row>
                                <Col sm="12">
                                    <div className=''>
                                        <LiveBoard game={this.props.game} />
                                    </div>
                                </Col>
                            </Row>
                        </TabPane>
                    </TabContent>
                </div>
            </div>
        );
    }
}

export default MatchCenterInfo
