import React, { Component } from 'react'
import Footer from "./Footer";
import api from '../config/api';
import { getSponsors, parseSponsors } from '../requests/apiCalls';
import axios from "axios";
import SiteHead from "./SiteHead";
import GalleryBody from "./GalleryBody";
import LoadingSpinner from "./LoadingSpinner";

class Gallery extends Component {

    constructor(props) {
        super(props)
        this.state = {
            id: this.props.match.params.id,
            loading: true,
            gallery: null,
            gallery_slides: [],
            sponsors: []
        }
    }

    getGallery() {
        return api.get(`/api/v1/galleries/${this.state.id}`)
    }

    componentDidMount() {
        axios.all([this.getGallery(), getSponsors()])
            .then(axios.spread((gallery, sponsors) => {
                let gallery_data = gallery.data.data
                let gallery_slides = []

                if(gallery_data) {
                    let data = gallery_data.attributes
                    if(data.gallery_images.length > 0) {
                        data.gallery_images.map((gallery_image, index) => (
                            gallery_slides.push( { original: gallery_image.image.gallery_image.url, thumbnail: gallery_image.image.gallery_image.url })
                        )) }
                    else {
                        data.old_images.map((gallery_image, index) => (
                            gallery_slides.push( { original: 'https://rubin-kazan.ru/media' + gallery_image[0], thumbnail: 'https://rubin-kazan.ru/media' + gallery_image[1] })
                        ))
                    }
                }
                this.setState({ gallery: gallery.data.data.attributes, gallery_slides: gallery_slides, sponsors: parseSponsors(sponsors.data), loading: false })
            }));
    }

    render() {
        const { loading, gallery, gallery_slides, sponsors} = this.state;
        return (
            <>
							{loading ? <LoadingSpinner /> :
								<>
									<SiteHead sponsors={sponsors}/>
									<GalleryBody gallery={gallery} gallery_slides={gallery_slides} />
									<Footer sponsors={sponsors} />
								</>	
							}
            </>
        );
    }
}

export default Gallery