import React, { Component } from 'react'
import YouTube from "@u-wave/react-youtube";
import Slider from "react-slick/lib";

class SpecialProject extends Component {

    render () {

        let settings = {
            dots: false,
            infinite: true,
            arrows: true,
            slidesToShow: 1,
            adaptiveHeight: false,
            slidesToScroll: 1,
            swipeToSlide: true,
            autoplay: true,
            speed: 1000,
            autoplaySpeed: 3000,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        adaptiveHeight: true,
                        infinite: true,
                        dots: false
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        adaptiveHeight: true,
                        slidesToScroll: 1,
                        initialSlide: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        adaptiveHeight: true,
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        return(
            <div className={'container-fluid custom_project' + (this.props.project.show_on_mobile ? '' : ' d-none d-sm-block') }>
                    <section>
                            <div className="card custom_project_card_wrapper">
                                <div className="row bg-img-rubin custom_project_row">
                                    {this.props.index % 2 === 0 ?
                                        <>
                                        <div className="col-md-6 text-center">
                                            <div className="card-block custom_project_card mt-5">
                                                <h4 className="card-title project_title">{this.props.project.title}</h4>
                                                <p className="card-text">{this.props.project.description}</p>
                                                <a href={this.props.project.post_url} className="project_button mb-3">Подробнее</a>
                                            </div>
                                        </div>
                                        <div className="col-md-6 project_img project_top">
                                            <div className="card-img-bottom">
                                                {this.props.project.video ?
                                                    <div className="embed-responsive embed-responsive-16by9">
                                                        <video className="embed-responsive-item slide-project" autoPlay loop muted>
                                                               <source src={this.props.project.video}
                                                               type="video/mp4"/>
                                                        </video>
                                                    </div>
                                                :
                                                    <>
                                                    {this.props.project.video_id ?
                                                        <>
                                                        <div className="embed-responsive embed-responsive-16by9">
                                                        <YouTube
                                                        width='100%'
                                                        height='100%'
                                                        video={this.props.project.video_id}/>
                                                        </div>
                                                        </>
                                                        :
                                                        <>
                                                            {this.props.project.project_images.length ?
                                                                <>
                                                                    <Slider className='main-slider-project' {...settings}>
                                                                        {this.props.project.project_images.map((img, index) => (
                                                                            <img alt={'project_image_' + img.id} key={img.id} className="img-fluid" src={img.image.project_page ? img.image.project_page.url : '/images/post_default.png'}/>
                                                                        ))}
                                                                    </Slider>
                                                                </>
                                                                :
                                                                <img alt={this.props.project.title}
                                                                     className="img-fluid"
                                                                     src={this.props.project.cover.project_page.url ? this.props.project.cover.project_page.url : '/images/post_default.png'}/>
                                                            }
                                                            </>
                                                    }
                                                    </>
                                                }
                                            </div>
                                        </div></> :
                                            <>
                                                <div className="col-md-6 project_img">
                                                    <div className="card-img-bottom">
                                                        {this.props.project.video ?
                                                            <div className="embed-responsive embed-responsive-16by9">
                                                                <video className="embed-responsive-item slide-project" autoPlay loop muted>
                                                                    <source src={this.props.project.video}
                                                                            type="video/mp4"/>
                                                                </video>
                                                            </div>
                                                            :
                                                            <>
                                                                {this.props.project.video_id ?
                                                                    <>
                                                                        <div className="embed-responsive embed-responsive-16by9">
                                                                            <YouTube
                                                                                width='100%'
                                                                                height='100%'
                                                                                video={this.props.project.video_id}/>
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        {this.props.project.project_images.length ?
                                                                            <>
                                                                                <Slider className='main-slider-project' {...settings}>
                                                                                    {this.props.project.project_images.map((img, index) => (
                                                                                        <img alt={'project_image_' + img.id} key={img.id} className="img-fluid" src={img.image.project_page ? img.image.project_page.url : '/images/post_default.png'}/>
                                                                                    ))}
                                                                                </Slider>
                                                                            </>
                                                                            :
                                                                            <img alt={this.props.project.title}
                                                                                 className="img-fluid"
                                                                                 src={this.props.project.cover.project_page.url ? this.props.project.cover.project_page.url : '/images/post_default.png'}/>
                                                                        }
                                                                    </>
                                                                }
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="col-md-6 text-center">
                                                        <div className="card-block custom_project_card mt-5">
                                                            <h4 className="card-title project_title">{this.props.project.title}</h4>
                                                            <p className="card-text">{this.props.project.description}</p>
                                                            <a href={this.props.project.post_url} className="project_button mb-3">Подробнее</a>
                                                        </div>
                                                    </div>

                                                </>
                                    }
                                </div>
                            </div>
                    </section>
            </div>
        )
    }
}

export default SpecialProject