import React, { Component } from 'react'
import SiteHead from "./SiteHead";
import Footer from "./Footer";
import api from "../config/api";
import axios from "axios";
import {getSponsors, parseSponsors} from "../requests/apiCalls";
import LoadingSpinner from "./LoadingSpinner";
import SearchResultsTypeWrapper from "./SearchResultsTypeWrapper";

class Results extends Component {

    constructor(props) {
        super(props)
        this.state = {
            query: window.document.URL.toString().split('?q=')[1],
            loading: true,
            sponsors: [],
            results: []
        }
        this.doSearch = this.doSearch.bind(this);
    }

    componentDidMount() {
        axios.all([this.doSearch(), getSponsors()])
            .then(axios.spread((results, sponsors) => {
                this.setState({ results: results.data, sponsors: parseSponsors(sponsors.data), loading: false })
            }));
    }

    doSearch() {
        return api.get('/api/v1/search.json?q=' + this.state.query)
    }

    render() {
        const { loading, results, sponsors } = this.state;
        return (
            <>
                {loading ? <LoadingSpinner/> :
                    <>
                        <SiteHead sponsors={sponsors}/>
                        <div className='content'>
                            <h3 className='text-center pt-3'>РЕЗУЛЬТАТЫ ПОИСКА</h3>
                            <br/>
                            <div className='container'>
                                {results.length !== 0 ?
                                    <>
                                        {results.map((items, index) => {
                                            return <SearchResultsTypeWrapper key={index} title={items[0][0]} items={items[0][1]} />
                                        })}
                                    </>
                                    :
                                    <>
                                        <div className='min-vh-100'><h5 className='rubin-red text-uppercase text-center'>Ничего не найдено</h5></div>
                                    </>
                                }
                            </div>
                        </div>
                        <Footer sponsors={sponsors} />
                    </>
                }
            </>
        );
    }
}

export default Results