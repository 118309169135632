import React, { Component } from 'react';
import SearchResultItem from "./SearchResultItem";

class SearchResultsTypeWrapper extends Component {

    render() {
        return (
            <>
                <h3 className='rubin-red text-uppercase'>{this.props.title}</h3>
                    <div className='row row-cards'>
                        {this.props.items.map((search_object) => {
                            return <SearchResultItem key={search_object.object.data.id} item={search_object.id} search_object={search_object} />
                        })}
                    </div>
                <hr/>
            </>
        );
    }
}

export default SearchResultsTypeWrapper
