import React from 'react';
import Cookies from 'universal-cookie';
import {withRouter} from 'react-router-dom';
import { Redirect } from 'react-router-dom'

class Navigation extends React.Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            isOpen: false,
            redirect: false,
            query: '',
            sponsorOne: null,
            sponsorTwo: null,
            sponsorOneSmall: null,
            sponsorTwoSmall: null
        };
        this.handleClick = this.handleClick.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.doRedirect = this.doRedirect.bind(this);
        this.state.sponsorOne = process.env.REACT_APP_CONNECT_STRING + this.props.sponsors[0][1][0].attributes.logo_wide.sponsor_main_wide.url;
        this.state.sponsorTwo = process.env.REACT_APP_CONNECT_STRING + this.props.sponsors[0][1][1].attributes.logo_wide.sponsor_main_wide.url;
        
        this.state.sponsorOneSmall = process.env.REACT_APP_CONNECT_STRING + this.props.sponsors[0][1][0].attributes.logo_small.sponsor_main_small.url;
        this.state.sponsorTwoSmall = process.env.REACT_APP_CONNECT_STRING + this.props.sponsors[0][1][1].attributes.logo_small.sponsor_main_small.url;

        this.state.sponsorLinkOne = this.props.sponsors[0][1][0].attributes.link_url
        this.state.sponsorLinkTwo = this.props.sponsors[0][1][1].attributes.link_url
    }

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    handleClick() {
        const cookies = new Cookies();
        let lang = cookies.get('locale');
        if(lang === 'ru') {
            cookies.set('locale', 'en', { path: '/' });
        } else {
            cookies.set('locale', 'ru', { path: '/' });
        }
        window.location.reload();
    }

    handleInputChange = () => {
        this.setState({
            query: this.search.value
        })
    }

    renderRedirect = () => {
        if (this.state.redirect) {
            return <Redirect to={"/results?q=" + this.state.query} />
        }
    }

    doRedirect(e) {
        if(e.keyCode === 13) {
            this.setState({
                redirect: true
            })
        }
    }

    render() {
        return (
            <>
                <header className="pagewide bg-img-rubin">
                    <div className="container">
                        <div className="row">
                            <div className='col-md-3 d-none d-lg-block text-2right custom_main_image'>
                                <div className='fullwrapper'>
                                    <a href='/' className='image_main_link'>
                                        <div className='wrapper'>
                                            <div className='d-inline-block main_text_one'>ФК РУБИН</div>
                                            <img className='d-inline-block head-image-one' src='/images/cup.png' alt='cup'/>
                                        </div>
                                        <div className='second_line'>
                                            <div className='d-inline-block main_text_two'>ОФИЦИАЛЬНЫЙ САЙТ</div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            {this.state.sponsorOne ? 
                                <>
                                <div className='col-md-2 d-none d-lg-block'>
                                    <a href={this.state.sponsorLinkOne}>
                                        <img className="mx-auto d-block customImg sponsor_logo_header" src={this.state.sponsorOne}/>
                                    </a>
                                </div>
                                <div className='d-none d-block d-sm-block d-md-block d-lg-none col-5 pl-4'>
                                    <a href={this.state.sponsorLinkOne}>
                                        <img className="mx-auto d-block customImgSmall" src={this.state.sponsorOneSmall}/>
                                    </a>
                                </div>
                                </> : ''
                            }
                            <div className="d-none d-md-block d-lg-block d-xl-block col-sm text-center">
                                <a href='/'>
                                    <img width='70' alt='logo' className="navbar_logo" src="/images/logo.png"/>
                                </a>
                            </div>
                            <div className="d-none d-sm-block d-md-none text-center col-4">
                                <a href='/'>
                                    <img width='70' alt='logo' className="navbar_logo" src="/images/logo.png"/>
                                </a>
                            </div>
                            <div className="d-block d-sm-none col-2 text-center">
                                <a href='/'>
                                    <img width='70' alt='logo' className="navbar_logo" src="/images/logo.png"/>
                                </a>
                            </div>
                            {this.state.sponsorTwo ? 
                                <>
                                <div className='col-md-2 d-none d-lg-block'>
                                    <a href={this.state.sponsorLinkTwo}>
                                        <img className="mx-auto d-block customImg sponsor_logo_header" src={this.state.sponsorTwo}/>
                                    </a>
                                </div>
                                <div className='d-none d-block d-sm-block d-md-block d-lg-none col-5 pr-4'>
                                    <a href={this.state.sponsorLinkTwo}>
                                        <img className="mx-auto d-block customImgSmall" src={this.state.sponsorTwoSmall}/>
                                    </a>
                                </div>
                                </>: ''
                            }

                            <div className="col-md-3 d-none d-lg-block text-right">
                                <div className='fullwrapper social_div'>
                                    <div className='col-xs-6 wrapper'>
                                        <div className='row row_social d-flex justify-content-end'>
                                            <div className='icon social fb'><a rel="noopener noreferrer" target='_blank' className="py-0" href="https://t.me/fcrk1958"><i className="fa fa-telegram"></i></a></div>
                                            <div className='icon social fb'><a rel="noopener noreferrer" target='_blank' className="py-0" href="https://twitter.com/fcrk"><i className="fa fa-twitter"></i></a></div>
                                            <div className='icon social fb'><a rel="noopener noreferrer" target='_blank' className="py-0" href="http://vk.com/fcrk"><i className="fa fa-vk"></i></a></div>
                                            <div className='icon social fb'><a rel="noopener noreferrer" target='_blank' className="py-0" href="http://youtube.com/FCRubinTV"><i className="fa fa-youtube"></i></a></div>
                                            <div className='icon social fb'><a rel="noopener noreferrer" target='_blank' className="py-0" href="https://www.tiktok.com/@fcrk"><div className='fa fa-tk'><svg aria-hidden="true" focusable="false" data-prefix="fab" height='20px' data-icon="tiktok" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="svg-inline--fa fa-tiktok fa-w-14"><path fill="currentColor" d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z" className=""></path></svg></div></a></div>
                                            <div className='icon social fb'><a rel="noopener noreferrer" target='_blank' className="py-0" href="#"><i className="fa fa-fake"></i></a></div>
                                        </div>
                                    </div>
                                    <div className='col-xs-6 second_line text-right'>
                                        <div className='d-inline-block main_text_two lang_pack'>
                                            {this.renderRedirect()}
                                            <input ref={input => this.search = input} onChange={this.handleInputChange} onKeyDown={this.doRedirect} name='q' className='search_bar col-xs-6' placeholder='Поиск' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
            </>
        );
    }
}

export default withRouter(Navigation);

