import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {Link} from "react-router-dom";

class MainSlider extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            minHeight: null
        }
    }

    render() {
        let settings = {
            dots: false,
            infinite: true,
            arrows: true,
            speed: 500,
            slidesToShow: 1,
            adaptiveHeight: false,
            slidesToScroll: 1,
            swipeToSlide: true,
            autoplay: true,
            autoplaySpeed: 4000,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        adaptiveHeight: true,
                        infinite: true,
                        dots: false
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        adaptiveHeight: true,
                        slidesToScroll: 1,
                        initialSlide: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        adaptiveHeight: true,
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        return (
                <Slider {...settings} className='pt-9 main_slides d-none d-sm-block'>
                    {this.props.posts.map((post, index) => (
                            <div className={"slick-slide slide item " + (post.attributes.video ? 'video' : 'image')} key={index}>
                                  {post.attributes.video ?
                                      <div className="">
                                        <video className="slide-video slide-media" style={{minHeight: this.state.minHeight}} autoPlay loop muted>
                                            <source src={post.attributes.video} type="video/mp4"/>
                                        </video>
                                      </div>:
                                    <img className='img-fluid' alt={post.attributes.title} src={post.attributes.cover.slider_image_small.url}/>
                                  }
                                <div className="slide-caption">
                                    <div className='row'>
                                        <div className='col-sm-12 col-md-12 col-xl-12 slider_text'>
                                            <Link className="title" to={`/posts/${post.attributes.slug}`}>{post.attributes.title}</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    ))}
                </Slider>
        );
    }
}

export default MainSlider