import React, { Component } from 'react'
import LoadingSpinner from "./LoadingSpinner";
import moment from "moment";
import Tag from "./Tag";
import Post from "./Post";
import Gallery from "./Gallery";
import MetaTags from 'react-meta-tags';
import {ReactTitle} from 'react-meta-tags';
import Alert from "reactstrap/es/Alert";

import {
    FacebookShareButton,
    FacebookIcon,
    TwitterShareButton,
    TwitterIcon,
    VKShareButton,
    VKIcon,
    OKShareButton,
    OKIcon
} from 'react-share';

import Voting from "./Voting";

class PostBody extends Component {

    constructor(props) {
        super(props)
        this.state = {
            url: window.location.href
        }
    }

    rawMarkup(){
        let rawMarkup = this.props.post.body
        return { __html: rawMarkup };
    }

    render () {
        return(
    <>
    <div className='container content post_body'>
        <main role="main">
            {this.props.loading ? <LoadingSpinner /> :
                <>
                    <ReactTitle title={this.props.post.title} />
                    <MetaTags>
                        <title>{this.props.post.title}</title>
                        <meta name="description" content={this.props.post.body} />
                        <meta property="og:title" content={this.props.post.title} />
                        <meta property="og:image" content={this.props.post.cover.post_page.url ? this.props.post.cover.post_page.url : '/images/post_default.png'} />
                    </MetaTags>

										{this.props.post.square_photo.url != null && this.props.post.cover.url == null ?
											<>
												<div className='row body_text_class mx-auto'>
														<div className="pt-3 pl-1 pt-md-5 rounded">
															<h2 className="blog-post-title">{this.props.post.title}</h2>
															<p className="blog-post-meta">{moment(this.props.post.created_at).format('DD MMM YYYY')}</p>
														</div>
												</div>
											</>
										: 
											<>
												{this.props.post.cover.url != null ?
													<>
															<div className='row'>
																	<div className="p-3 p-md-5 rounded">
																			<h2 className="blog-post-title">{this.props.post.title}</h2>
																			<p className="blog-post-meta">{moment(this.props.post.created_at).format('DD MMM YYYY')}</p>
																			<img alt={this.props.post.title} className="img-fluid" src={this.props.post.cover.post_page.url ? this.props.post.cover.post_page.url : '/images/post_default.png'}/>
																	</div>
															</div>
													</>
													:
													<>
															<div className='row body_text_class mx-auto'>
																	<div className="p-3 pt-5 rounded">
																			<h2 className="blog-post-title">{this.props.post.title}</h2>
																			<p className="blog-post-meta">{moment(this.props.post.created_at).format('DD MMM YYYY')}</p>
																					<img alt={this.props.post.title} className="img-fluid" src={`https://rubin-kazan.ru/media` + this.props.post.rsite_thumb_url}/>
																	</div>
															</div>
													</>
												}
											</>
										}

                    <div className="row body_text_class mx-auto">
                        <div className="col-md-8 blog-main">
                            <TwitterShareButton
                                url={this.state.url}
                                quote={this.props.post.title}
                                className="some-network">
                                <TwitterIcon
                                    size={32}
                                    round={false} />
                            </TwitterShareButton>

                            <VKShareButton
                                url={this.state.url}
                                quote={this.props.post.title}
                                windowWidth={660}
                                windowHeight={460}
                                className="some-network">
                                <VKIcon
                                    size={32}
                                    round={false} />
                            </VKShareButton>

                            <OKShareButton
                                url={this.state.url}
                                quote={this.props.post.title}
                                className="some-network">
                                <OKIcon
                                    size={32}
                                    round={false} />
                            </OKShareButton>
                            <hr/>
                            <div className="blog-post">
                                <div className="post_body_text" dangerouslySetInnerHTML={this.rawMarkup()}/>
                            </div>
                            <div className="tag_list">
                                {this.props.post.tags.map((tag) => {
                                    return (<Tag tag={tag} key={tag.id} />)
                                })}
                            </div>
                            {this.props.gallery_slides.length > 0 &&
                                <Gallery sidebar={'bottom'} gallery_slides={this.props.gallery_slides} loading={this.props.loading}/>
                            }

                            {(this.props.voting && this.props.voting.id && !this.props.already_voted) &&
                                <Voting voting={this.props.voting} loading={this.props.loading}/>
														}

														{(this.props.voting && this.props.voting.id && this.props.already_voted) &&
                              <div className='row justify-content-center'>  
																<Alert color="danger">
																	Спасибо, Ваш голос учтён
																</Alert>
															</div>
														}
														<hr/>
                        </div>
                        <aside className="col-md-4 blog-sidebar">
                            <div className={'row row-cards'}>
                                {this.props.similar.map((post) => {
                                    return <Post key={post.id} cssClass={'col-md-12 col-xl-12 mb-3'} post={post.attributes} />
                                })}
                            </div>
                        </aside>
                    </div>
                </>}
        </main>
    </div>
    </>
        )
    }
}

export default PostBody