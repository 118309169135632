import React, { Component } from 'react'
import LoadingSpinner from "./LoadingSpinner";
import Footer from "./Footer";
import SiteHead from "./SiteHead";
import GalleriesContainer from "./GalleriesContainer";

class GalleriesBody extends Component {
    render() {
        return (
            <>
                {this.props.loading ? <LoadingSpinner/> :
                    <>
                        <SiteHead sponsors={this.props.sponsors}/>
                        <div className='content mb-5 long_top'>
                            <GalleriesContainer onPageChange={this.props.onPageChange} pagination={this.props.pagination} galleries={this.props.galleries}/>
                        </div>
                        <Footer sponsors={this.props.sponsors} />
                    </>
                }
            </>
        );
    }
}

export default GalleriesBody
