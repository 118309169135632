import React, { Component } from 'react'
import Footer from "./Footer";
import api from '../config/api';
import VideoBody from "./VideoBody";
import SimilarVideosContainer from "./SimilarVideosContainer";
import { getSponsors, parseSponsors } from '../requests/apiCalls';
import axios from "axios";
import SiteHead from "./SiteHead";
import LoadingSpinner from "./LoadingSpinner";

class VideoPage extends Component {

    constructor(props) {
        super(props)
        this.state = {
            id: this.props.match.params.id,
            loading: true,
            video: null,
            similar: [],
            sponsors: []
        }
    }

    getVideos() {
        return api.get(`/api/v1/videos/${this.state.id}`)
    }

    componentDidMount() {
        axios.all([this.getVideos(), getSponsors()])
            .then(axios.spread((videos_data, sponsors) => {
                this.setState({ loading: false, video: videos_data.data.video.data.attributes, similar: videos_data.data.similar.data, sponsors: parseSponsors(sponsors.data) })
            }));
    }

    render() {
        const { loading, video, similar, sponsors} = this.state;
        return (
            <>
            {loading ? <LoadingSpinner/> :
                <>
                    <SiteHead sponsors={sponsors}/>
                    <VideoBody video={video} loading={loading}/>
                    <SimilarVideosContainer videos={similar} cssClass='col-xl-3' loading={loading}/>
                    <Footer sponsors={sponsors} loading={loading}/>
                </>
            }
        </>
        );
    }
}

export default VideoPage