import React, { Component } from 'react'
import api from '../config/api';
import axios from 'axios';
import { getSponsors, parseSponsors } from '../requests/apiCalls';
import GalleriesBody from "./GalleriesBody";
import RubinPagination from "./RubinPagination";

class GalleriesPage extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            galleries: [],
            sponsors: [],
            page: 1,
            total: 52
        }
        this.onPageChange = this.onPageChange.bind(this);
    }

    onPageChange(page) {
        this.setState({loading: true});
        axios.all([this.getGalleries(page)])
            .then(axios.spread((galleries) => {
                this.setState({ page: galleries.data.page, total: galleries.data.pages, galleries: galleries.data.data, loading: false})
            }));
    }

    getGalleries(page = 1) {
        return api.get('/api/v1/galleries.json?page=' + page)
    }

    componentDidMount() {
        axios.all([this.getGalleries(), getSponsors()])
            .then(axios.spread((galleries, sponsors) => {
                this.setState({ page: galleries.data.page, total: galleries.data.pages, galleries: galleries.data.data, sponsors: parseSponsors(sponsors.data), loading: false})
            }));
    }

    render() {
        const { loading, galleries, sponsors, page, total } = this.state;
        return (
            <>
                <GalleriesBody pagination={<RubinPagination onPageChange={this.onPageChange.bind(this)} page={page} total={total} />} loading={loading} sponsors={sponsors} galleries={galleries} />
            </>
        );
    }
}

export default GalleriesPage