import React, { Component } from 'react'
let reactUltimatePaginationBootstrap4 = require("react-ultimate-pagination-bootstrap-4")
const UltimatePagination = reactUltimatePaginationBootstrap4.default;

class RubinPagination extends Component {


    render() {
        return (
            <UltimatePagination
                currentPage={this.props.page}
                totalPages={this.props.total}
                onChange={this.props.onPageChange}
            />
        );
    }
}

export default RubinPagination