/* eslint-disable */
import React from 'react';
import Cookies from 'universal-cookie';
import {Link, withRouter} from "react-router-dom";
import $ from 'jquery';
import 'superfish';

class MiddleMenu extends React.Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);

        this.state = {
            query: '',
            results: [],
            isOpen: false,
        };

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        const cookies = new Cookies();
        let lang = cookies.get('locale');
        if(lang === 'ru') {
            cookies.set('locale', 'en', { path: '/' });
        } else {
            cookies.set('locale', 'ru', { path: '/' });
        }
        window.location.reload();
    }

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    componentDidMount() {
        $('.nav-menu').superfish({
            animation: {
                opacity: 'show'
            },
            speed: 400
        });

        if ($('#nav-menu-container').length) {

			let body = $('body');
			if ($('#mobile-nav').length === 0) {
                let $mobile_nav = $('#nav-menu-container').clone().prop({
                    id: 'mobile-nav'
                });
                $mobile_nav.find('> ul').attr({
                    'class': '',
                    'id': ''
                });

                body.append($mobile_nav);
                body.prepend('<button type="button" id="mobile-nav-toggle"><i class="lnr lnr-menu"></i></button>');
                body.append('<div id="mobile-body-overly"></div>');
                $('#mobile-nav').find('.menu-has-children .sf-with-ul').prepend('<i class="lnr lnr-chevron-down"></i>');
                $('#mobile-nav').find('#super_search').prepend("<input id='search_input' autoComplete='off' name='q' placeholder='Поиск' />");
                $('#mobile-nav').append("<hr/><p class='link_full'>Полная версия сайта</p>");
			}

            $('.menu-has-children a').unbind().click(function(e) {
                $(this).next().toggleClass('menu-item-active');
                $(this).nextAll('ul').eq(0).slideToggle();
            });

            $('#mobile-nav-toggle').unbind().click(function(e) {
                $('body').toggleClass('mobile-nav-active');
                $('#mobile-nav-toggle i').toggleClass('lnr-cross lnr-menu');
                $('#mobile-body-overly').toggle();
            });

            $(document).click(function(e) {
                let container = $("#mobile-nav, #mobile-nav-toggle");
                if (!container.is(e.target) && container.has(e.target).length === 0) {
                    if (body.hasClass('mobile-nav-active')) {
                        body.removeClass('mobile-nav-active');
                        $('#mobile-nav-toggle i').toggleClass('lnr-cross lnr-menu');
                        $('#mobile-body-overly').fadeOut();
                    }
                }
            });


            $(".link_full").on( "click", function() {
							const cookies = new Cookies();
							cookies.set('desktop', true);
							window.location.reload();
						});

            $("#search_input").change(function () {
                window.location.replace("/results?q=" + $(this).val());
            });

        } else if ($("#mobile-nav, #mobile-nav-toggle").length) {
            $("#mobile-nav, #mobile-nav-toggle").hide();
        }
    }

    render() {
        return (
            <>
                <div className="main-menu">
                    <div className="row align-items-center justify-content-center d-flex">
                        <nav id='nav-menu-container'>
                            <ul className="nav-menu">
																<li className=''>
																	<div id='super_search' className='search_menu'>
																	</div>
																</li>
                                <li className="menu-has-children"><a href="javascript:void(0)">Новости</a>
                                    <ul className='inner_menu'>
                                        <li><Link className='main_news_section' to={`/news`}>Все новости</Link></li>
                                        <li><Link to={`/news/club`}>Новости клуба</Link></li>
                                        <li><Link to={`/news/main_team`}>Новости главной команды</Link></li>
                                        <li><Link to={`/news/youth_team`}>Новости второй команды</Link></li>
                                        <li><Link to={`/news/women_team`}>Новости женского футбола</Link></li>
                                        <li><Link to={`/news/academy`}>Новости академии</Link></li>
                                    </ul>
                                </li>

                                <li className="menu-has-children"><a href="javascript:void(0)">Команды</a>
                                    <ul>
                                        <li><Link to={`/teams/rubin/players`}>Главная команда</Link></li>
                                        <li><Link to={`/teams/rubin-2/players`}>Рубин-2</Link></li>
                                        <li><Link to={`/pages/rubin_youth`}>Рубин-М</Link></li>
										<li><Link to={`/pages/ufl`}>ЮФЛ </Link></li>
                                        <li><Link to={`/pages/women`}>Женская</Link></li>
                                        <li><Link to={`/pages/w_youth`}>Женская молодежная</Link></li>
                                    </ul>
                                </li>

                                <li className="menu-has-children"><a href="javascript:void(0)">Сезон</a>
                                    <ul>
                                        <li><Link to={`/rubin/calendar`}>Расписание и результаты</Link></li>
                                        <li><Link to={`/rubin/standings`}>Турнирная таблица</Link></li>
                                        <li><Link to={`/rubin_youth/calendar`}>Матчи Рубин-2</Link></li>
										<li><Link to={`/pages/ufl_calendar`}>Матчи ЮФЛ</Link></li>
                                        <li><Link to={`/pages/women_calendar`}>Матчи женской команды</Link></li>
                                    </ul>
                                </li>

                                <li className="menu-has-children"><a href="javascript:void(0)">Клуб</a>
                                    <ul>
                                        <li>
                                            <Link to={`/pages/club`}>Основная информация о клубе</Link>
                                        </li>
                                        <li>
                                            <Link to={`/pages/history`}>История</Link>
                                        </li>
                                        <li>
                                            <Link to={`/pages/Legends`}>Легенды</Link>
                                        </li>
                                        <li>
                                            <Link to={`/pages/board`}>Правление</Link>
                                        </li>
                                        <li>
                                            <Link to={`/pages/management`}>Руководство</Link>
                                        </li>
                                        <li>
                                            <Link to={`/pages/logo`}>Логотип</Link>
                                        </li>
                                        <li>
                                            <Link to={`/pages/infrastructure`}>Инфраструктура</Link>
                                        </li>
                                        <li>
                                            <Link to={`/pages/sponsors`}>Партнеры</Link>
                                        </li>
                                        <li>
                                            <Link to={`/pages/rubin2`}>Рубин-2</Link>
                                        </li>
                                    </ul>
                                </li>

                                <li className="menu-has-children"><a href="javascript:void(0)">Медиа</a>
                                    <ul className='inner_menu'>
                                        <li className='root_menu'>
                                            <Link to={`/videos`}>Видео</Link>
                                        </li>
                                        <li className='root_menu'>
                                            <Link to={`/galleries`}>Фото</Link>
                                        </li>

                                        <li className='root_menu'>
                                            <a href='javascript:void(0)' className='main_news_section'>Пресс-Центр</a>
                                        </li>
                                        <li>
                                            <Link to={`/news/press`}>Для СМИ</Link>
                                        </li>
                                        <li>
                                            <Link to={`/pages/accreditation`}>Правила аккредитации</Link>
                                        </li>
                                    </ul>
                                </li>

                                <li className="menu-has-children"><a href="javascript:void(0)">Болельщикам</a>
										<ul className='inner_menu'>
                                        <li className='root_menu'>
                                            <a href='javascript:void(0)' className='main_news_section'>Проекты</a>
                                        </li>
                                        <li>
                                            <Link to={`/pages/kids`}>Выведи футболиста</Link>
                                        </li>
                                        <li>
                                            <Link to={`/pages/dream`}>Дари мечту</Link>
                                        </li>
                                        <li>
                                            <Link to={`/pages/lesson`}>Урок с "Рубином"</Link>
                                        </li>
                                        <li className='root_menu simple_menu'>
                                            <Link to={`/pages/appfcrk`} className='main_news_section'>Мобильное приложение</Link>
                                        </li>
                                        <li className='root_menu simple_menu'>
                                            <Link to={`/pages/mgn`} className='main_news_section'>«Рубин» доступен каждому</Link>
                                        </li>
                                        <li className='root_menu simple_menu'>
                                            <Link to={`/pages/browser`} className='main_news_section'>Рубиновый браузер</Link>
                                        </li>
                                        <li className='root_menu simple_menu'>
                                            <Link to={`/pages/viewers-rules`} className='main_news_section'>Правила поведения зрителей</Link>
                                        </li>
                                        <li className='root_menu simple_menu'>
                                            <Link to={`/pages/parking`} className='main_news_section'>Парковка</Link>
                                        </li>
                                    </ul>
                                </li>

                                
                                <li><a href="http://store.rubin-kazan.ru/ru/">Магазин</a></li>
                                <li className="menu-has-children"><a href="javascript:void(0)">Билеты</a>
                                    <ul>
                                    <li className='root_menu main_bold'>
                                        <a href={`https://rubin-kazan.ru/posts/pervyy-novogodniy-spektakl-rubinovaya-yolka-v-istorii`}>Рубиновая Ёлка</a>
                                      </li>
																			<li className='root_menu'>
                                        <a href={`http://tickets.rubin-kazan.ru/`}>Билеты и абонементы онлайн</a>
                                      </li>
																			<li className='root_menu'>
                                        <Link to={`/pages/tickets`}>Билетная программа</Link>
                                      </li>	
																			<li className='root_menu'>
                                        <Link to={`/pages/abonement`}>Абонементная программа</Link>
                                      </li>	
                                                                            <li className='root_menu'>
                                        <Link to={`/pages/vip`}>ВИП-ложи</Link>
                                      </li>	
                                                                            <li className='root_menu'>
                                        <Link to={`/pages/fancard`}>Карта болельщика</Link>
                                      </li>	
                                    </ul>
                                </li>


                                <li className="menu-has-children"><a href="javascript:void(0)">Академия</a>
                                    <ul>
									  <li className='root_menu'>
                                        <Link to={`/pages/academy`}>Об Академии</Link>
                                      </li>
									  <li className='root_menu'>
                                        <Link to={`/pages/academy-management`}>Руководство и персонал</Link>
                                      </li>	
                                      <li className='root_menu'>
                                        <Link to={`/pages/academy-coaches`}>Тренерский состав</Link>
                                      </li>	
                                      <li className='root_menu'>
                                        <Link to={`/pages/academy-glory`}>Достижения</Link>
                                      </li>	
                                      <li className='root_menu'>
                                        <Link to={`/pages/academy-casting`}>Просмотр детей</Link>
                                      </li>	
                                      <li className='root_menu'>
                                        <Link to={`/pages/academy_players`}>Наши воспитанники</Link>
                                      </li>	
                                      <li className='root_menu'>
                                        <Link to={`/pages/academy_documents`}>Методические пособия</Link>
                                      </li>	
                                    </ul>
                                </li>
                            </ul>
                            </nav>
                    </div>
                </div>
            </>
        );
    }
}

export default withRouter(MiddleMenu)

