import React, { Component } from 'react'
import { ListGroupItem } from 'reactstrap';
import Button from "reactstrap/es/Button";
import api from '../config/api';
import axios from "axios";

class VotingItem extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false
        }
        this.handleClick = this.handleClick.bind(this);
    }

    callVote() {
        api.post("/api/v1/votings/" + this.props.voting_id + "/voting_items/" + this.props.id + "/vote")
    }

    handleClick() {
        axios.all([this.callVote()])
            .then(axios.spread((data) => {
                this.setState({ loading: false })
                this.props.setVoted();
            }));
    }

    render () {
        return(
                <ListGroupItem>
                    <span className='custom_vote_item'>{this.props.voting_item.option_text}</span>
                    <Button className='float-right rubin_btn load_more_btn' onClick={this.handleClick}>Голосовать!</Button>
                </ListGroupItem>
        )
    }
}

export default VotingItem