import React, { Component } from 'react'
import Sponsor from "./Sponsor";

class SponsorRow extends Component {

    render() {
			return (
					<div className='col-md-12 col-12 sponsor_row_div'>
						{this.props.sponsors.map((sponsor, index) => {
								return (<Sponsor index={index} size={this.props.sponsors.length} sponsor={sponsor} key={sponsor.id} />)
						})}
					</div>
			);
    }
}

export default SponsorRow