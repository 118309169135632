import React, { Component } from 'react'
import SponsorRow from "./SponsorRow";

class SponsorsContainer extends Component {

    render() {
        return (
					<div className='row justify-content-center row_sponsors text-center'>
						{this.props.sponsors.map((e, index) => {
							return <SponsorRow key={e} sponsors={e[1]}/>
						})}
					</div>
        );
    }
}

export default SponsorsContainer