import React, { Component } from 'react'

class PlayerStatisticsRow extends Component {

    render () {
        return(
            <tr>
                <td>{this.props.player.number}</td>
                <td>{this.props.player.fullname}</td>
                <td>{this.props.player.games_played}</td>
                <td>{this.props.player.minutes_played}</td>
                <td>{this.props.player.goals}</td>
                <td>{this.props.player.assists}</td>
                <td>{this.props.player.yellow_cards}</td>
                <td>{this.props.player.red_cards}</td>
                <td>{this.props.player.missed_goals}</td>
                <td>{this.props.player.cleansheets}</td>
            </tr>
        )
    }
}

export default PlayerStatisticsRow