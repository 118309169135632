import React, { Component } from 'react'
import { Card, CardImg, CardBody, CardTitle, CardFooter } from 'reactstrap';
import moment from 'moment'
import { Link } from 'react-router-dom';

class Post extends Component {
    render () {
        return(
            <div className={this.props.cssClass}>
                <Link className="post_link" to={`/posts/${this.props.post.slug}`}>
                    <Card data-id={this.props.post.id} className="main_card h-100">
												{this.props.post.cover.url == null ?
													<>
														{this.props.post.rsite_thumb_url != null ?
                            	<CardImg top src={`https://rubin-kazan.ru/media` + this.props.post.rsite_thumb_url} alt={this.props.post.title} />
														:
															<CardImg top src={this.props.post.square_photo.square.url} alt={this.props.post.title} />
														}
													</>
                        :
													<>
															{this.props.post.square_photo.url != null ?
																<CardImg top src={this.props.post.square_photo.url != null ? this.props.post.square_photo.square.url : "/images/cover_default.png"} alt={this.props.post.title} />
															:
																<CardImg top src={this.props.post.cover.old_site.url != null ? this.props.post.cover.old_site.url : "/images/cover_default.png"} alt={this.props.post.title} />
															}
													</>
                        }
                        <CardBody>
                            <CardTitle className="post-card-title">{this.props.post.title}</CardTitle>
                        </CardBody>
                        <CardFooter>
                            {moment(this.props.post.created_at).tz('Europe/Moscow').format("DD.MM.YYYY в HH:mm")}
                            {this.props.post.post_type === 'gallery' &&
                                <i className="small_icon fa fa-camera float-right"/>
                            }
                            {this.props.post.has_video === true &&
                            <i className="small_icon fa fa-video-camera float-right"/>
                            }
                            {this.props.post.has_video === false && this.props.post.post_type !== 'gallery' &&
                            <i className="small_icon fa fa-file-text-o float-right"/>
                            }
                        </CardFooter>
                    </Card>
                </Link>
            </div>
        )
    }
}

export default Post