import React from 'react';

class VotingLoadingSpinner extends React.Component {

    render() {
        return (
            <div className="center-div">
                <div className='load_spinner'>
                    <div>
                                <img alt='player' width='70' src={this.props.voting_photo} />
																<p className='thanks_vote'>Спасибо, что выбрали меня</p>
                                <div><i className="fa fa-spinner fa-spin" /> Загрузка...</div>
                    </div>
                </div>
            </div>
        )
    }
}

export default VotingLoadingSpinner;
