import React, { Component } from 'react'
import PostsContainer from "./PostsContainer";
import Footer from "./Footer";
import SiteHead from "./SiteHead";
import LoadingSpinner from "./LoadingSpinner";

class NewsHubBody extends Component {

    render() {
        return (
            <>
                {this.props.loading ? <LoadingSpinner/> :
                    <>
                        <SiteHead sponsors={this.props.sponsors}/>
                        <div className='content min-vh-100'>
                            {this.props.posts.length > 0 ?
                                <PostsContainer posts={this.props.posts}/>
                                :
                                <div className='text-center'><h5 className='rubin-red text-uppercase'>Ничего не найдено</h5></div>
                            }
                        </div>
                        <div className="container">
                            <div className="row h-100 justify-content-center align-items-center">
                                {this.props.pagination}
                            </div>
                        </div>
                        <Footer sponsors={this.props.sponsors} />
                    </>
                }
            </>
        );
    }
}

export default NewsHubBody