import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import SiteHead from "./SiteHead";

class NotFound extends Component {

    render() {
        return (
                    <>
                        {/* <SiteHead sponsors={sponsors}/> */}
                        <div className='container content post_body text-center not_found_top'>
                            <main role="main">
                                <h1 className='not_found'>404</h1>
                                <p>Страница не найдена</p>
                                <Link className='not_found_link' to='/'>На главную</Link>
                            </main>
                        </div>
                    </>
        );
    }
}

export default NotFound