import React, { Component } from 'react'
import api from '../config/api';
import axios from "axios";
import {getSponsors, parseSponsors} from "../requests/apiCalls";
import TeamBody from "./TeamBody";

class TeamPage extends Component {

    constructor(props) {
        super(props)
        this.state = {
            team_id: this.props.match.params.team_id,
            loading: true,
            data: null,
            sponsors: []
        }
    }

    parsePlayers(response) {
        const groupedMap = response.data.reduce(
            (entryMap, e) => entryMap.set(e.attributes.squad_id, [...entryMap.get(e.attributes.squad_id)||[], e]),
            new Map()
        );
        let arr = []
        for (const entry of groupedMap.entries()) {
            arr.unshift(entry)
        }
        return arr
    }

    getPlayers() {
        return api.get(`/api/v1/teams/${this.state.team_id}/players`)
    }

    componentDidMount() {
        axios.all([this.getPlayers(), getSponsors()])
            .then(axios.spread((players, sponsors) => {
                this.setState({ data: this.parsePlayers(players.data), loading: false, sponsors: parseSponsors(sponsors.data) })
            }));
    }

    render() {
        const { team_id, loading, data, sponsors } = this.state;
        return (
            <>
                <TeamBody data={data} teamId={team_id} sponsors={sponsors} loading={loading}/>
            </>
        );
    }
}

export default TeamPage