/* eslint-disable */

import React from "react";

class BannerContainer extends React.Component {

    render() {
        return (
            <div className='container-fluid text-center pt-3 pb-3'>
                    <div className="row">
                      <iframe frameBorder="0" width="100%" src="banner/index.html"></iframe>
                    </div>
            </div>
        );
    }
}

export default BannerContainer