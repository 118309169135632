import React, { Component } from 'react'
import {Link} from "react-router-dom";

class SquadPlayerItem extends Component {

    render() {
        return (
            <div className='slide'>
                <Link className="player_link" to={`/teams/${this.props.teamId}/players/${this.props.player.slug}`}>
                    <img alt={this.props.player.fullname} src={this.props.player.photo.player_preview.url ? this.props.player.photo.player_preview.url : '/images/player_default.png'} />
                    <h5 className='text-center player_first_name'>{this.props.player.first_name}</h5>
                    <h3 className='text-center player_last_name'>{this.props.player.last_name}</h3>
										{this.props.player.number ?
											<h4 className='text-center player_number'>{this.props.player.number}</h4>
											:
											<h6 className='text-center player_number'>{this.props.player.club_position}</h6>
										}
                </Link>
            </div>
        );
    }
}

export default SquadPlayerItem